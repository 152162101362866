import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';

import { AuthorizerModule } from '@pure/authz-authorizer';
import { UIModule } from '../ui/ui.module';
import { ArraysTagsViewComponent } from './arrays-tags-view/arrays-tags-view.component';
import { TagChangeSummaryComponent } from './tag-change-summary/tag-change-summary.component';
import { TagsSharedModule } from './tags.shared.module';

@NgModule({
    imports: [AuthorizerModule, CommonModule, Angulartics2Module, NgbModule, TagsSharedModule, UIModule],
    declarations: [ArraysTagsViewComponent, TagChangeSummaryComponent],
    exports: [ArraysTagsViewComponent],
})
export class TagsModule {}
