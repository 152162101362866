<div class="card-flip small-card">
    <div class="card-front">
        <div
            (mouseenter)="hoverState = true"
            (mouseleave)="hoverState = false"
            [ngClass]="{ 'eol-hw': hasEolComponents() }"
            class="card"
        >
            <eol-overlay *ngIf="hasEolComponents()" [array]="array" [hovering]="hoverState"></eol-overlay>

            <!-- Card top -->
            <array-card-header
                (onFlipCard)="flipCard($event.event)"
                [alerts]="alerts"
                [array]="$any(array)"
                [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
                class="card-header"
            >
            </array-card-header>

            <!-- Show regular card if array is not under Expired Status AND user is not allowed to view Appliance Card -->
            <ng-container
                *ngIf="!showWarningCardForOutOfSupportAppliance || isCardShowArrayInfo; else showOutOfSupportContract"
            >
                <!-- Show regular card unless status is suspended (only applicable to CBS arrays) -->
                <ng-container *ngIf="array.active_status !== ResourceStatus.SUSPENDED; else showSuspended">
                    <!-- Card front - array is current -->
                    <div *ngIf="array.isCurrent">
                        <div class="container-fluid array-info">
                            <div class="row">
                                <div class="col-xs-6">{{ array.model }}</div>
                                <div class="col-xs-6">{{ array.os }} {{ array.version }}</div>
                            </div>
                        </div>

                        <div class="card-array-stats">
                            <div class="card-array-stats-capacity">
                                <capacity-dial
                                    [arcs]="[
                                        {
                                            value: $any(array.capacityData.normalizedPercFull),
                                            cssClass: getCapacityDialCssClass()
                                        }
                                    ]"
                                    [size]="125"
                                ></capacity-dial>

                                <div class="card-capacity">
                                    <div class="card-capacity-perc">{{ array.capacityData.normalizedPercFull }}</div>
                                    <div class="card-capacity-str">{{ getCapacityString() }}</div>
                                    <div
                                        *ngIf="capacityDownLicensingEnabled && array.capacityData.capacityExpandable"
                                        class="card-capacity-down-licensing-icon-container"
                                    >
                                        <capacity-down-licensing-icon
                                            [applianceId]="array.id"
                                        ></capacity-down-licensing-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="card-array-stats-dr">
                                <div class="data-reduction-ratio">{{ array.capacityData.dataReductionStr }}</div>
                                <div class="data-reduction-string">Data Reduction</div>
                            </div>
                            <div
                                *ngIf="array.safeModeStatus !== safeModeStatusEnum.NOT_AVAILABLE"
                                class="card-safemode-icon-container"
                            >
                                <hive-menu #menu="hiveMenu" position="left">
                                    <hive-menu-item
                                        [queryParams]="getSafeModeUIParams(array)"
                                        [routerLink]="getSafeModeState()"
                                        label="View Assessment"
                                    >
                                    </hive-menu-item>
                                    <hive-menu-item
                                        (click)="openSafeModeMultipartAuthModal(array)"
                                        label="Change SafeMode"
                                    >
                                    </hive-menu-item>
                                </hive-menu>
                                <safemode-shield-icon
                                    *ngIf="
                                        safeModeMultipartyFeatureFlag &&
                                        array.safeModeStatus !== safeModeStatusEnum.UPGRADE_REQUIRED
                                    "
                                    [angularticsLabel]="array.safeModeStatus"
                                    [arraySafeModeStatus]="array.safeModeStatus"
                                    [menuRef]="menu"
                                    angulartics2On="click"
                                    angularticsAction="Arrays - click safemode icon"
                                    angularticsCategory="Action"
                                    hiveMenuTrigger
                                >
                                </safemode-shield-icon>
                                <a
                                    *ngIf="
                                        !safeModeMultipartyFeatureFlag ||
                                        array.safeModeStatus === safeModeStatusEnum.UPGRADE_REQUIRED
                                    "
                                    [angularticsLabel]="array.safeModeStatus"
                                    [queryParams]="getSafeModeUIParams(array)"
                                    [routerLink]="getSafeModeState()"
                                    validateHref="PURE1:read:safemode"
                                    angulartics2On="click"
                                    angularticsAction="Arrays - click safemode icon"
                                    angularticsCategory="Action"
                                >
                                    <safemode-shield-icon
                                        [arraySafeModeStatus]="array.safeModeStatus"
                                    ></safemode-shield-icon>
                                </a>
                            </div>
                        </div>

                        <div class="card-perf-wrapper">
                            <div class="card-perf-stats clearfix latency">
                                <div class="card-perf-title"
                                    >Latency
                                    <div class="perf-unit">(ms)</div>
                                </div>
                                <div
                                    [ngClass]="{
                                        'dual-latency': array.isFlashArray() && !$any(array).hasMirroredWrites,
                                        'triple-latency': array.isFlashBlade() || $any(array).hasMirroredWrites
                                    }"
                                    class="card-perf-metrics"
                                >
                                    <div class="card-perf-read">
                                        <div class="perf-type">R</div>
                                        <div class="perf-value">{{ array.performanceData.readLatencyStr.value }}</div>
                                    </div>
                                    <div class="card-perf-divider"></div>
                                    <div class="card-perf-write">
                                        <div class="perf-type">W</div>
                                        <div class="perf-value">{{ array.performanceData.writeLatencyStr.value }}</div>
                                    </div>
                                    <div class="card-perf-divider second"></div>
                                    <div
                                        *ngIf="array.isFlashArray() && $any(array).hasMirroredWrites"
                                        class="card-perf-other"
                                    >
                                        <div class="perf-type">MW</div>
                                        <div class="perf-value">{{
                                            array.performanceData.mirroredWriteLatencyStr.value
                                        }}</div>
                                    </div>
                                    <div *ngIf="array.isFlashBlade()" class="card-perf-other">
                                        <div class="perf-type">O</div>
                                        <div class="perf-value">{{ array.performanceData.otherLatencyStr.value }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-perf-stats clearfix">
                                <div class="card-perf-metrics">
                                    <div class="card-perf-read">
                                        <div class="card-perf-title">IOPS</div>
                                        <div class="perf-value">{{ array.performanceData.iopsStr.value }}</div>
                                        <div class="perf-unit">{{ array.performanceData.iopsStr.unit }}</div>
                                    </div>

                                    <div class="card-perf-divider"></div>
                                    <div class="card-perf-write">
                                        <div class="card-perf-title">Bandwidth</div>
                                        <div class="perf-value">{{ array.performanceData.bandwidthStr.value }}</div>
                                        <div class="perf-unit">{{ array.performanceData.bandwidthStr.unit }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Card front - array is not current -->
                    <div *ngIf="!array.isCurrent" class="appliance-not-current">
                        <pureui-reporting-icon></pureui-reporting-icon>
                        <p>Last updated {{ array.lastUpdated }}</p>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #showOutOfSupportContract>
                <ng-container
                    *ngIf="
                        arraysManager.isEndOfContractCardViewEnabled(eocEnabled, array.support_expiration_date);
                        else expiredView
                    "
                >
                    <appliance-out-of-contract-template [selectedArray]="array"></appliance-out-of-contract-template>
                </ng-container>
                <ng-template #expiredView>
                    <appliance-expired-template
                        [analyticsHeader]="'Appliances - Summary Card'"
                        [renewingIconSize]="16"
                        [selectedArrayExistingQuote]="selectedArrayExistingQuote"
                        [selectedArray]="array"
                        [supportIconSize]="35"
                    >
                    </appliance-expired-template>
                </ng-template>
            </ng-template>

            <ng-template #showSuspended>
                <div class="appliance-not-current">
                    <pureui-svg height="30" svg="suspended.svg"></pureui-svg>
                    <p>Suspended</p>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- Card back -->
    <array-card-back
        (onFlipCard)="flipCard($event.event)"
        [alerts]="alerts"
        [array]="array"
        [removeInternalsFromDom]="!hasCardEverFlipped"
    >
    </array-card-back>
</div>
