import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeatureFlagService, FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { WINDOW } from '../../app/injection-tokens';
import { FeatureFlagResource } from '../models/feature-flag';
import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagDxpService extends GenericService<FeatureFlagResource> {
    private readonly featureFlags = new Map<string, Observable<FeatureFlagStatus>>();

    constructor(
        protected http: HttpClient,
        @Inject(WINDOW) window: Window,
        private featureFlagService: FeatureFlagService,
    ) {
        super({
            resourceClass: FeatureFlagResource,
            endpoint: '',
        });

        // Expose a debugging method to override the values for feature flags
        // Can be called manually via browser console. Eg:
        //      PURE_FEATURE_FLAG_OVERRIDE('license-latency-chart', true)
        if (window) {
            (window as any).PURE_FEATURE_FLAG_OVERRIDE = (featureName: string, enabled: boolean): void => {
                this.featureFlags.set(
                    featureName,
                    of({
                        name: featureName,
                        enabled: enabled,
                    }),
                );
            };
        }
    }

    getFeatureFlag(name: string): Observable<FeatureFlagStatus> {
        if (!this.featureFlags.has(name)) {
            const request$ = this.featureFlagService.getFeatureFlags(name).pipe(
                take(1),
                map(response => response[name]),
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        console.error(
                            `getFeatureFlag(${name}) failed. Status: ${err.status} - Message: ${err.statusText}`,
                            err,
                        );
                    } else {
                        console.error(`getFeatureFlag(${name}) failed`, err);
                    }
                    this.featureFlags.delete(name); // Clear cached value on error
                    return of<FeatureFlagStatus>(null);
                }),
                shareReplay(1), // Cache the result - don't refetch on every subscribe,
            );
            this.featureFlags.set(name, request$);
        }

        return this.getCachedFeatureFlag(name).pipe(map(flag => _.cloneDeep(flag)));
    }

    getFeatureFlagsByNameArray(featureFlagNames: string[]): Observable<FeatureFlagStatus>[] {
        return featureFlagNames.map(name => this.getFeatureFlag(name));
    }

    private getCachedFeatureFlag(featureFlagName: string): Observable<FeatureFlagStatus> {
        return this.featureFlags.get(featureFlagName);
    }
}
