import { Pipe, PipeTransform } from '@angular/core';

import { ChassisModel } from 'core/src/forecast/hardware-capacity/models/array-capacity-config';

@Pipe({
    name: 'chassisModel',
    pure: true,
})
export class ChassisModelPipe implements PipeTransform {
    transform(value: ChassisModel): string {
        switch (value) {
            case 'PLATINUM':
                return 'X/C/E Chassis Gen1';
            case 'NICKEL':
                return 'X/C/E Chassis Gen2';
            case 'OXYGEN':
                return 'XL Chassis Gen1';
            case '400_CHASSIS':
            default:
                return '';
        }
    }
}
