<div class="modal-header">
    <button
        type="button"
        class="close modal-x-button pull-right"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Service Catalog Orders - Edit Expansion interactions"
        angularticsLabel="Closed modal"
        (click)="activeModal.dismiss()"
    >
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title" *ngIf="!subscription.isPoc"
        >{{ getManagementModalTitle() }} <b>{{ subscription.name }}</b></h3
    >
    <h3 class="modal-title" *ngIf="subscription.isPoc"
        >Quote for <b>{{ subscription.name }}</b></h3
    >
</div>

<div class="modal-body" #modalBody>
    <label class="form-section-title">Licenses</label>
    <ng-container *ngFor="let license of existingLicenses; let i = index; trackBy: 'id' | trackByProperty">
        <update-license
            class="existing-license-capacity-expansion-container"
            *ngIf="license.reservedAmount > 0"
            [license]="license"
            [programType]="subscription.programType"
            [subscription]="subscription"
            [(increaseAmount)]="increaseReserved[i]"
            [isQuoteRenewal]="isQuoteRenewal"
        >
        </update-license>
    </ng-container>
    <new-license
        class="license"
        *ngFor="let license of newLicenses; let i = index; trackBy: 'id' | trackByProperty"
        [license]="license"
        [subscription]="subscription"
        [firstExistingLicenseType]="licenses[0].licenseType"
        [isQuoteRenewal]="isQuoteRenewal"
        (delete)="deleteNewLicense(i)"
        (licenseChange)="onLicenseChange($event, i)"
    >
    </new-license>
    <section class="add-license-panel" *ngIf="!subscription.isPoc">
        <div class="add-license" [ngClass]="{ disabled: !newLicensesDataValid() }" (click)="addLicense()">
            <span class="add-sign">+</span>
            <label class="add-sign-label">Add License</label>
        </div>
    </section>
    <div class="renewal-info-container" *ngIf="isQuoteRenewal">
        <table class="renew-info-table">
            <thead class="renew-info-thead">
                <tr>
                    <th>Renewal Term</th>
                </tr>
            </thead>
            <tbody class="renew-info-tbody">
                <tr class="renew-info">
                    <td class="renewal-selection">
                        <span>
                            <ng-select
                                class="left-actions"
                                bindLabel="displayText"
                                bindValue="termInMonths"
                                [clearable]="false"
                                [searchable]="false"
                                [items]="renewalTermOptions"
                                [(ngModel)]="selectedRenewalOption"
                                angulartics2On="change"
                                angularticsCategory="Action"
                                angularticsAction="Subscription Management Modal - Renewal Term Selection Changed"
                            >
                            </ng-select>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <partner-info
        *ngIf="isQuoteRenewal"
        [partnersInfos]="partnersInfos"
        [isEditOrder]="isEditOrder"
        [activeOrder]="quoteBeingEdited"
        (partnerRequestChanged)="onPartnerRequestChanged($event)"
    ></partner-info>
    <div class="additional-info-container">
        <hive-text-area
            [(ngModel)]="additionalInstructionsText"
            label="Additional Instructions (optional):"
            size="small"
            [assistanceText]="additionalInstructionsText.length + '/' + maxCommentLength + ' characters'"
            [errorMessages]="
                additionalInstructionsText.length > maxCommentLength
                    ? additionalInstructionsText.length + '/' + maxCommentLength + ' characters'
                    : ''
            "
            maxlength="3000"
        >
        </hive-text-area>
        <div
            class="additional-emails-label"
            *ngIf="
                !quoteBeingEdited || (quoteBeingEdited.quoteType === 'RENEWAL' && !quoteBeingEdited.pure1Acknowledged)
            "
        >
            Email Notifications (optional):
            <pureui-svg
                svg="info-circle.svg"
                [height]="13"
                class="pstg-action-icon header-info-icon"
                ngbTooltip="Add a list of comma separated email addresses for notifications."
                placement="right"
                container="body"
            >
            </pureui-svg>
        </div>
        <hive-text-field
            *ngIf="
                !quoteBeingEdited || (quoteBeingEdited.quoteType === 'RENEWAL' && !quoteBeingEdited.pure1Acknowledged)
            "
            [(ngModel)]="additionalEmails"
        ></hive-text-field>
    </div>
</div>
<div class="modal-footer">
    <div class="agree-and-total-increase-container">
        <hive-checkbox [(ngModel)]="agreedOnTermsAndContracts">
            I agree to the
            <a href="https://www.purestorage.com/content/dam/pdf/en/legal/pure-enduser-agreement.pdf" target="_blank"
                >End User Agreement.</a
            >
        </hive-checkbox>
        <div class="total-capacity-increase">
            <label class="total-reserved-amount-increased-label">{{
                (subscription.isPoc ? 'Total Reserved ' : 'Total Expected Increase ') + reservedUnit
            }}</label>
            <reserved-amount-increased
                [licenseType]="licenseType"
                [subscription]="subscription"
                [currentAmount]="totalCurrentReserved"
                [increaseAmount]="totalIncreased()"
            >
            </reserved-amount-increased>
        </div>
    </div>
    <div class="message-and-buttons-container">
        <div class="error-message">
            <span *ngIf="hasError"
                >We cannot complete your request right now, please try again later. If this problem persists, please
                contact support.</span
            >
        </div>
        <div class="buttons-container">
            <button
                type="button"
                id="cancelButton"
                class="btn btn-info"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Service Catalog Orders - Edit Expansion interactions"
                angularticsLabel="Closed modal"
                (click)="activeModal.dismiss()"
            >
                Cancel
            </button>
            <div
                class="button-wrapper-for-tooltip"
                [disableTooltip]="isValid()"
                [ngbTooltip]="validationTooltip"
                tooltip-class="manage-modal-validation-tooltip"
                placement="top"
                container="body"
            >
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="(isValidPermission() | isNotAllowedImpure) || !isValid()"
                    (click)="requestAQuote()"
                >
                    <ng-container *ngIf="!isLoading">{{ getManagementModalSubmitButtonLabel() }}</ng-container>
                    <ng-container *ngIf="isLoading">Working...</ng-container>
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #requestConfirmedModal let-modal>
    <request-confirmed-modal [activeModal]="modal"> </request-confirmed-modal>
</ng-template>
