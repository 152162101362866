<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.close()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <section class="modal-title">Request Evergreen Subscription Renewal for Array {{ selectedArray.name }}</section>
</div>
<ng-container *ngIf="!isSubmitted; else showSuccess">
    <div class="modal-body">
        <div class="error-message" *ngIf="requestFailed">Request unsuccessful. Please try again</div>
        <div class="reach-out-message">
            <ng-container *ngIf="!isValidToRequest; else requestedMsg">
                A renewal was requested on
                {{ selectedArray.contract_last_renew_requested | moment2date | date: 'yyyy-MM-dd' }}. For updates on
                your request, please contact <a href="mailto:renewals@purestorage.com" target="_blank">Renewals</a>.
            </ng-container>
            <ng-template #requestedMsg>
                The Renewals team will review your request and reach out to you promptly.
            </ng-template>
        </div>
    </div>
    <div class="modal-footer">
        <hive-button (click)="activeModal.close()" emphasis="secondary" label="Cancel" class="cancel-button">
        </hive-button>
        <hive-button
            emphasis="primary"
            class="submit-button"
            (click)="submitInquiry()"
            [disabled]="('PURE1:write:request_quote_for_existing' | isNotAllowedImpure) || !isValidToRequest"
            label="Submit"
        >
        </hive-button>
    </div>
</ng-container>
<ng-template #showSuccess>
    <div class="success-body">
        <pureui-svg svg="green-arrow.svg" width="30"></pureui-svg>
        <span class="success-message">
            Request sent. A Pure Storage representative will contact you within 14 business days.
        </span>
    </div>
</ng-template>
