<div *ngIf="!notification.isUpdate; else updateFlag">
    <div *ngIf="!notification.isComingSoon" class="banner-icon">
        <pureui-svg svg="new-big-badge.svg" [height]="83.25"></pureui-svg>
    </div>
    <div *ngIf="notification.isComingSoon" class="banner-icon">
        <pureui-svg svg="coming-soon-badge.svg" [height]="83.25"></pureui-svg>
    </div>
</div>

<ng-template #updateFlag>
    <div class="banner-icon">
        <pureui-svg svg="update-badge.svg" [height]="83.25"></pureui-svg>
    </div>
</ng-template>

<button
    class="dismiss-button svg-hover-dropshadow"
    (click)="dismissNotification.emit()"
    pure-read-only
    angulartics2On="click"
    angularticsCategory="Action"
    [angularticsAction]="'Banner - Close banner'"
    [angularticsLabel]="notification.title"
>
    <pureui-svg svg="close-button.svg" [height]="12.39"></pureui-svg>
</button>
<div class="banner-content">
    <div class="banner-graphic">
        <pureui-svg
            [svg]="notification.image"
            [width]="notification.id === pure360BannerId ? 100 : 60"
            [ngClass]="{ 'large-image': notification.id === pure360BannerId }"
        ></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title">
            {{ notification.title }}
        </div>
        <div class="banner-body">
            {{ notification.body }}
            <span class="kb-links">
                <ng-container *ngIf="notification.linksTemplate; else defaultLinksTemplate">
                    <ng-container *ngTemplateOutlet="notification.linksTemplate"></ng-container>
                </ng-container>
                <ng-template #defaultLinksTemplate>
                    <ng-container *ngIf="notification.kbArticleLink">
                        <a
                            [href]="notification.kbArticleLink"
                            target="_blank"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Read more"
                            [angularticsLabel]="notification.title"
                        >
                            Read More
                        </a>
                    </ng-container>
                    <ng-container *ngFor="let link of notification.customLinks; let i = index">
                        <ng-container *ngIf="notification.kbArticleLink || i !== 0">|</ng-container>
                        <a
                            [href]="link.url"
                            [target]="link.openInNewTab ? '_blank' : '_self'"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Custom link - {{ link.displayName }}"
                            [angularticsLabel]="notification.title"
                        >
                            {{ link.displayName }}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!notification.hideReleaseNotes && !notification.isComingSoon">
                        |
                        <a
                            href="https://support.purestorage.com/Pure1/Pure1_Manage/001_Overview/000:_Pure1_Manage_Release_Notes"
                            target="_blank"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Release notes"
                            [angularticsLabel]="notification.title"
                        >
                            Release Notes
                        </a>
                    </ng-container>
                </ng-template>
            </span>
        </div>
    </div>
    <div class="banner-actions">
        <ng-container *ngIf="notification.buttonTemplate; else pageRedirectButton">
            <ng-container *ngTemplateOutlet="notification.buttonTemplate"></ng-container>
        </ng-container>
        <ng-template #pageRedirectButton>
            <ng-container *ngIf="notification.externalCTAButton; else tryItOut">
                <a
                    class="btn btn-primary try-button"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                    [href]="notification.externalCTAButton.url"
                    [target]="notification.externalCTAButton.openInNewTab ? '_blank' : '_self'"
                >
                    {{ notification.externalCTAButton.displayName }}
                </a>
            </ng-container>
            <ng-template #tryItOut>
                <a
                    *ngIf="notification.path"
                    class="btn btn-primary try-button"
                    [routerLink]="notification.path"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                >
                    {{ notification.isComingSoon ? 'Learn more' : 'Try it out' }}
                </a>
            </ng-template>
        </ng-template>
    </div>
</div>
