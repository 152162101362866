import { TileGroup } from '../../ui/honeycomb-chart/tile-group';
import { Resource } from '../interfaces/resource';
import moment from 'moment';
import { SecurityAssessmentLevel } from './assessment-security-array';
import { Theme } from '../../ui/styles/theme';
import { flatten } from 'lodash';

export class ArrayData {
    name: string;
    assessmentDataTimestamp: number;
    id: string;
    applianceID: string;
    apartmentID: string;
    purityVersion: string;
    model?: string;
    excludedFromAssessment: boolean;
    notPhoningHome?: boolean;
    overallAssessmentLevel: AssessmentLevel;
    volumeAssessment?: IAssessmentData; //FA Block
    directoryAssessment?: IAssessmentData; //FA File
    bucketAssessment?: IAssessmentData; //FB Object
    fileSystemAssessment?: IAssessmentData; //FB File
    insightsV2?: DataProtectionV2Insight[];
    dataReductionRatio?: number;
    overallResiliencyScore: ResiliencyScore;
    dataProtectionPlugins?: PluginDetails[];
    totalUsableCapacityBytes?: number;
    capacityUtilisationPercentage?: number;
    fbSnapshotsKeepFor?: moment.Duration;

    constructor(response: ArrayData) {
        this.name = response.name;
        this.assessmentDataTimestamp = response.assessmentDataTimestamp;
        this.applianceID = response.applianceID;
        this.apartmentID = response.apartmentID;
        this.purityVersion = response.purityVersion;
        this.model = response.model;
        this.excludedFromAssessment = response.excludedFromAssessment;
        this.notPhoningHome = response.notPhoningHome;
        this.overallAssessmentLevel = response.overallAssessmentLevel;
        this.volumeAssessment = response.volumeAssessment;
        this.directoryAssessment = response.directoryAssessment;
        this.bucketAssessment = response.bucketAssessment;
        this.fileSystemAssessment = response.fileSystemAssessment;
        this.insightsV2 = response.insightsV2;
        this.dataReductionRatio = response.dataReductionRatio;
        this.overallResiliencyScore = response.overallResiliencyScore;
        this.dataProtectionPlugins = response.dataProtectionPlugins;
        this.totalUsableCapacityBytes = response.totalUsableCapacityBytes;
        this.capacityUtilisationPercentage = response.capacityUtilisationPercentage;
        this.fbSnapshotsKeepFor = response.fbSnapshotsKeepFor
            ? moment.duration(response.fbSnapshotsKeepFor, 's')
            : null;

        //Just for satisfying the Resource interface
        this.id = this.applianceID;
    }
}

export class PluginDetails {
    name?: string;
    vendor: string;
    version?: string;
    numberOfAppliances?: number;

    constructor(response: PluginDetails) {
        this.name = response.name;
        this.vendor = response.vendor;
        this.version = response.version;
        this.numberOfAppliances = response.numberOfAppliances;
    }
}

export class FleetData implements Resource {
    id: string;
    name: string;
    assessmentGenerationTimestamp?: number;
    overallResiliencyScore?: ResiliencyScore;
    overallResiliencyScoreWithFlashBlade?: ResiliencyScore;
    numberOfFlashArrays?: number;
    numberOfFlashBlades?: number;
    totalNumberOfAppliances?: number;
    numberOfAppliancesWithBasicFeatures?: number;
    numberOfAppliancesWithAdvancedFeatures?: number;
    numberOfExcludedAppliances: number;
    numberOfExcludedObjects: number;
    updateOfUnderlyingExclusionRules: boolean;
    countsOfAppliancesWithBasicFeatures?: ApplianceCount;
    countsOfAppliancesWithAdvancedFeatures?: ApplianceCount;
    appliancesWithDataProtectionPlugins?: PluginDetails[];

    constructor(response: FleetData) {
        this.assessmentGenerationTimestamp = response.assessmentGenerationTimestamp;
        this.overallResiliencyScore = response.overallResiliencyScore;
        this.overallResiliencyScoreWithFlashBlade = response.overallResiliencyScoreWithFlashBlade;
        this.numberOfFlashArrays = response.numberOfFlashArrays;
        this.numberOfFlashBlades = response.numberOfFlashBlades;
        this.totalNumberOfAppliances = response.totalNumberOfAppliances;
        this.numberOfAppliancesWithBasicFeatures = response.numberOfAppliancesWithBasicFeatures;
        this.numberOfAppliancesWithAdvancedFeatures = response.numberOfAppliancesWithAdvancedFeatures;
        this.numberOfExcludedAppliances = response.numberOfExcludedAppliances;
        this.numberOfExcludedObjects = response.numberOfExcludedObjects;
        this.updateOfUnderlyingExclusionRules = response.updateOfUnderlyingExclusionRules;
        this.countsOfAppliancesWithBasicFeatures = response.countsOfAppliancesWithBasicFeatures;
        this.countsOfAppliancesWithAdvancedFeatures = response.countsOfAppliancesWithAdvancedFeatures;
        this.appliancesWithDataProtectionPlugins = response.appliancesWithDataProtectionPlugins?.map(
            plugin => new PluginDetails(plugin),
        );
    }
}

export interface IAssessmentData {
    assessmentLevel: AssessmentLevel;
    snapshotMakingResult?: SnapshotAssessmentResult;
    replicationResult?: SnapshotAssessmentResult;
    safeMode: {
        enabled: boolean;
        eradicationTimerInDays: number | null;
        granularSafemodeEnabled: boolean;
    };
    granularSafeModeResult?: {
        numberOfProtectedVolumes: number;
        sizeOfProtectedVolumes: number;
        numberOfCompliantProtectedVolumes: number;
        sizeOfCompliantProtectedVolumes: number;
    };
    numberOfEntities: number;
    capacityOfEntities: number;
    drr?: number;
    resiliencyScore?: ResiliencyScore;
}

export enum DataProtectionV2EntityType {
    volume = 'VOLUME',
    directory = 'DIRECTORY',
    bucket = 'BUCKET',
    fileSystem = 'FILESYSTEM',
}

export const DataProtectionV2EntityOrder = {
    [DataProtectionV2EntityType.volume]: 1,
    [DataProtectionV2EntityType.directory]: 2,
    [DataProtectionV2EntityType.fileSystem]: 3,
    [DataProtectionV2EntityType.bucket]: 4,
};

export type DataProtectionV2FlashArrayEntityType =
    | DataProtectionV2EntityType.volume
    | DataProtectionV2EntityType.directory;
export type DataProtectionV2FlashBladeEntityType =
    | DataProtectionV2EntityType.bucket
    | DataProtectionV2EntityType.fileSystem;

export type PurityVersion = `${number}.${number}.${number}`;

export enum SnapshotMakingResultAssessmentLevel {
    REVIEW_REQUIRED = 'REVIEW_REQUIRED',
    MEETS_GUIDANCE = 'MEETS_GUIDANCE',
}

export enum SafeModeStatus {
    ENABLED = 'Enabled',
    DISABLED = 'Disabled',
    GRANULAR_ENABLED = 'Granular Enabled',
}

export enum DataProtectionV2InsightCategory {
    safeMode = 'SAFE_MODE',
    eradicationTimer = 'ERADICATION_TIMER',
    localSnapshots = 'LOCAL_SNAPSHOTS',
    replication = 'REPLICATION',
    anomaly = 'ANOMALY',
}

export interface ApplianceCount {
    numberOfFlashArrays: number;
    numberOfFlashBlades: number;
}

export interface ResiliencyScore {
    localSnapshotsPercentage: number;
    localSnapshotsSafeModeProtectedPercentage: number;
    replicationPercentage: number;
    activeDrPercentage: number;
    activeClusterPercentage: number;
    offloadPercentage: number;
    safeModeProtectedPercentage: number;
    bucketsVersioningPercentage: number;
    bucketsObjectLockPercentage: number;
    basic: number;
    advanced: number;
    totalScore: number;
}

export interface DataProtectionV2Insight {
    type: DataProtectionV2InsightType;
    category: DataProtectionV2InsightCategory;
    entityType: DataProtectionV2EntityType;
    data: {
        purityVersionCurrent?: PurityVersion;
        purityVersionThreshold?: PurityVersion;
        capacityUsed?: number;
        capacityThreshold?: number;
        eradicationTimerCurrent?: number;
        eradicationTimerThreshold?: number;
        replicationAssessmentLevel?: AssessmentLevel;
        replicationAssessmentLevelThreshold?: AssessmentLevel;
        copyMakingAssessmentLevel?: AssessmentLevel;
        copyMakingAssessmentLevelThreshold?: AssessmentLevel;
        storageSpecific?: boolean;
        numberOfVolumes?: number;
        totalNumberOfVolumes?: number;
        percentageOfVolumes?: number;
    };
}

export enum DataProtectionV2InsightType {
    UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE = 'UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE',
    UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE = 'UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE',
    INCREASE_CAPACITY_FOR_ARRAY_WIDE = 'INCREASE_CAPACITY_FOR_ARRAY_WIDE',
    TURN_ON_ARRAY_WIDE = 'TURN_ON_ARRAY_WIDE',
    UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER = 'UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER',
    INCREASE_ERADICATION_TIMER = 'INCREASE_ERADICATION_TIMER',
    UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS = 'UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS',
    UPDATE_POLICY_FOR_REPLICATION = 'UPDATE_POLICY_FOR_REPLICATION',
    UPGRADE_PURITY_VERSION_FOR_REPLICATION = 'UPGRADE_PURITY_VERSION_FOR_REPLICATION',
    INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE = 'INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE',
    SWITCH_P_GROUP_TO_ARRAY_WIDE = 'SWITCH_P_GROUP_TO_ARRAY_WIDE',
    TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK = 'TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK',
    TURN_ON_P_GROUP = 'TURN_ON_P_GROUP',
    INCREASE_CAPACITY_FOR_P_GROUP = 'INCREASE_CAPACITY_FOR_P_GROUP',
    DRR_DROP = 'DRR_DROP',
}

export enum AssessmentLevel {
    Caution = 'CAUTION',
    Optimizable = 'OPTIMIZABLE',
    Good = 'GOOD',
    Advanced = 'ADVANCED',
}

export function toAssessmentLevel(val: number): AssessmentLevel | null {
    switch (val) {
        case 0:
            return AssessmentLevel.Caution;
        case 1:
            return AssessmentLevel.Optimizable;
        case 2:
            return AssessmentLevel.Good;
        case 3:
            return AssessmentLevel.Advanced;
        default:
            return null;
    }
}

export interface SnapshotAssessmentResult {
    numberOfEntitiesPerLevel: {
        REVIEW_REQUIRED?: number;
        MEETS_GUIDANCE?: number;
    };
    capacityOfEntitiesPerLevel: {
        REVIEW_REQUIRED?: number;
        MEETS_GUIDANCE?: number;
    };
}

export interface AssessmentHoneycombTile {
    id: string;
    getAssessmentLevel: () => string;
}

export const GroupOrderedIds: Record<string, number> = {
    [AssessmentLevel.Caution]: 0,
    [SecurityAssessmentLevel.NeedsImprovement]: 1,
    // SecurityAssessmentLevel.OPTIMIZABLE is already present
    [AssessmentLevel.Optimizable]: 2,
    // SecurityAssessmentLevel.GOOD is already present
    [AssessmentLevel.Good]: 3,
    [AssessmentLevel.Advanced]: 4,
    [SecurityAssessmentLevel.Excellent]: 5,
    EXCLUDED: 6,
    NOT_PHONING_HOME: 7,
};

export const TileGroups: Record<keyof typeof GroupOrderedIds, TileGroup> = {
    [AssessmentLevel.Caution]: {
        groupOrderedId: GroupOrderedIds[AssessmentLevel.Caution],
        name: 'Caution',
        color: '#FF7070',
    },
    [SecurityAssessmentLevel.NeedsImprovement]: {
        groupOrderedId: GroupOrderedIds[SecurityAssessmentLevel.NeedsImprovement],
        name: 'Needs Improvement',
        color: '#FF7070',
        borderColor: '#FF7070',
    },
    [AssessmentLevel.Optimizable]: {
        groupOrderedId: GroupOrderedIds[AssessmentLevel.Optimizable],
        name: 'Optimizable',
        color: '#FFC121',
    },
    [AssessmentLevel.Good]: {
        groupOrderedId: GroupOrderedIds[AssessmentLevel.Good],
        name: 'Good',
        color: '#AFD105',
    },
    [AssessmentLevel.Advanced]: {
        groupOrderedId: GroupOrderedIds[AssessmentLevel.Advanced],
        name: 'Advanced',
        color: '#30BA45',
    },
    [SecurityAssessmentLevel.Excellent]: {
        groupOrderedId: GroupOrderedIds[SecurityAssessmentLevel.Excellent],
        name: 'Excellent',
        color: '#30BA45',
        borderColor: '#30BA45',
    },
    EXCLUDED: {
        groupOrderedId: GroupOrderedIds.EXCLUDED,
        name: 'Excluded',
        color: '#F7F7F7',
        iconColor: '#DDDDDD',
        borderColor: '#DDDDDD',
    },
    NOT_PHONING_HOME: {
        groupOrderedId: GroupOrderedIds.NOT_PHONING_HOME,
        name: 'Not Phoning Home',
        color: '#F7F7F7',
        borderColor: '#FF7070',
        icon: 'x-action.svg',
        iconColor: '#FF7070',
    },
};

export enum TableViewEnum {
    RESILIENCY = 'Resiliency',
    ASSESSMENT = 'Assessment',
}

export class AssessmentAnomalyVolume implements Resource {
    readonly id: string;
    readonly name: string;

    applianceID: string;
    volumeID: string;
    anomalyStartDate: number;
    anomalyEndDate: number;
    volumeName: string;
    anomalyId: string;
    valueChangePercentage: number;
    color?: string;
    volumeSnapshots?: SnapshotData[];

    constructor(response: any) {
        this.id = this.volumeID = response.volumeID;
        this.name = this.volumeName = response.volumeName;
        this.applianceID = response.applianceID;
        this.anomalyStartDate = response.anomalyStartDate;
        this.anomalyEndDate = response.anomalyEndDate;
        this.anomalyId = response.anomalyId;
        this.valueChangePercentage = response.valueChangePercentage;
        this.volumeSnapshots = response.volumeSnapshots;
    }

    /**
     * Set color for the volume, selected first unselected color.
     * Return updated volume instance.
     * @param set if color should be selected/unselected
     */
    setVolumeColor(set = true): AssessmentAnomalyVolume {
        if (set) {
            if (this.color) {
                return this;
            }
            const selectedColor = ANOMALY_CHART_COLORS.find(c => !c.selected);
            if (selectedColor) {
                this.color = selectedColor.color;
                selectedColor.selected = true;
            }
        } else {
            const selectedColor = ANOMALY_CHART_COLORS.find(c => c.color === this.color);
            this.color = undefined;
            selectedColor && (selectedColor.selected = false);
        }
        return this;
    }
}

export interface SnapshotData {
    applianceID: string;
    applianceName: string;
    snapshotName: string;
    timestamp: number;
}

export class LatencyAssessmentAnomalyVolume extends AssessmentAnomalyVolume {
    valueChangePercentageRead?: number;
    valueChangePercentageWrite?: number;

    constructor(response: LatencyAssessmentAnomalyVolume, readValue: number, writeValue: number) {
        super(response);
        this.valueChangePercentageRead = readValue;
        this.valueChangePercentageWrite = writeValue;
    }
}

export class AssessmentAnomalyTimeSeries implements Resource {
    id: string;
    name: string;
    applianceID: string;
    volumeID: string;
    volumeName: string;
    anomalyStartDate: number;
    anomalyEndDate: number;
    points: AssessmentAnomalyChartValue[];

    constructor(response: AssessmentAnomalyTimeSeries) {
        this.id = this.applianceID = response.applianceID;
        this.name = this.volumeName = response.volumeName;
        this.volumeID = response.volumeID;
        this.anomalyStartDate = response.anomalyStartDate;
        this.anomalyEndDate = response.anomalyEndDate;
        this.points = response.points.map(point => new AssessmentAnomalyChartValue(point));
    }

    isEnoughData(): boolean {
        return this.anomalyStartDate && this.anomalyEndDate && this.points.length && this.points.some(p => p.value > 0);
    }

    toChartDataFromOptionalMetrics(): Map<string, [number, number]> {
        const metrics = new Set(flatten(this.points.map(point => Object.keys(point.optionalMetrics))));
        const data = new Map();
        metrics.forEach(metric => {
            data.set(
                metric,
                this.points.map(point => [point.timestamp, point.optionalMetrics[metric] || 0]),
            );
        });

        return data;
    }
}

export class AssessmentAnomalyChartValue {
    timestamp: number;
    value: number;
    optionalMetrics: any;

    constructor(response: any) {
        this.timestamp = response.timestamp;
        this.value = response.value;
        this.optionalMetrics = response.optionalMetrics;
    }

    toChartData(): [number, number] {
        return [this.timestamp, this.value];
    }

    toChartSANData(): [number, number] {
        return [this.timestamp, this.optionalMetrics?.valueSAN];
    }

    toChartArrayData(): [number, number] {
        return [this.timestamp, this.optionalMetrics?.valueArray];
    }
}

export const ANOMALY_CHART_COLORS = [
    {
        color: Theme.dpa.orange,
        selected: false,
    },
    {
        color: Theme.dpa.lightBlue,
        selected: false,
    },
    {
        color: Theme.dpa.purple,
        selected: false,
    },
];
