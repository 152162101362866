import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlohaArray } from '../models/aloha-array';
import { GenericService } from './generic.service';
import { unifiedArrayDefaultParams } from './unified-array.service';

@Injectable({ providedIn: 'root' })
export class SecurityPatchFb20250107Service extends GenericService<AlohaArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AlohaArray,
            defaultParams: unifiedArrayDefaultParams,
            endpoint: '/rest/v1/security-patch-fb-2025-01-07',
            create: true,
        });
    }
}
