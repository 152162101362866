import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    AllOf,
    IsAllowed,
    IsFeatureDisabled,
    IsFeatureEnabled,
    MENU_SEPARATOR_TITLE,
    SomeOf,
    TabConfig,
} from '../services/tabs/tabs.model';
import { FeatureNames } from '../model/FeatureNames';
import { AuthorizationService } from '@pure/authz-authorizer';
import { FeatureFlagDxpService } from '@pure1/data';

const isIdpAvailable = () => {
    // Query idp to see if user has access to SSO page
    const http = inject(HttpClient);
    return http.head('/api/1.0/sso-config').pipe(
        take(1),
        map(() => true),
        catchError(() => of(false)),
    );
};

const needsSecurityPatch = (patchName: string) => {
    const http = inject(HttpClient);
    const authorizationService = inject(AuthorizationService);
    const featureFlagService = inject(FeatureFlagDxpService);

    return authorizationService.isAllowed('PURE1:read:security_patching').pipe(
        take(1),
        switchMap(isAllowed => {
            if (!isAllowed) {
                return of(false);
            }
            return featureFlagService.getFeatureFlag(patchName).pipe(
                take(1),
                switchMap(feature => {
                    if (feature?.enabled) {
                        return http.get(`/rest/v1/${patchName}`).pipe(
                            take(1),
                            map((alohaData: any[]) => alohaData && alohaData.length > 0),
                            catchError(() => of(false)),
                        );
                    } else {
                        return of(false);
                    }
                }),
            );
        }),
    );
};

export const MENU: TabConfig[] = [
    {
        title: 'DASHBOARD',
        icon: 'sidenav-dashboard.svg',
        path: '/dashboard',

        items: [
            {
                title: 'Overview',
                path: '/dashboard/overview',

                condition: IsAllowed('PURE1:read:dashboard'),
            },
            {
                title: 'Assessment',
                path: '/dashboard/assessment',

                condition: SomeOf(
                    AllOf(
                        IsAllowed('PURE1:read:assessment_data_protection'),
                        IsFeatureEnabled(FeatureNames.DATA_PROTECTION_V2),
                    ),
                    AllOf(
                        IsAllowed('PURE1:read:assessment_sustainability'),
                        IsFeatureEnabled(FeatureNames.SUSTAINABILITY),
                    ),
                    AllOf(
                        IsAllowed('PURE1:read:assessment_security'),
                        IsFeatureEnabled(FeatureNames.SECURITY_ASSESSMENT),
                    ),
                ),
            },
            {
                title: 'Software Lifecycle',
                path: '/dashboard/software-lifecycle',

                condition: SomeOf(
                    AllOf(
                        IsAllowed('PURE1:read:purity_optimization'),
                        IsFeatureEnabled(FeatureNames.PURITY_OPTIMIZATION),
                    ),
                    IsAllowed('PURE1:read:upgrade_prechecks'),
                ),
            },
        ],
    },
    {
        title: 'MARKETPLACE',
        icon: 'sidenav-marketplace.svg',
        path: '/services/servicecatalog',

        condition: AllOf(IsAllowed('PURE1:read:subscription'), IsFeatureEnabled(FeatureNames.SERVICE_CATALOG)),

        items: [
            {
                title: 'Catalog',
                path: '/services/servicecatalog/main',
            },
            {
                title: 'Orders',
                path: '/services/servicecatalog/orders',
            },
        ],
    },
    {
        title: 'ASSETS',
        path: '/assets',
        icon: 'sidenav-asset-management.svg',

        condition: IsAllowed('PURE1:read:subscription'),
    },
    {
        title: 'FLEET',
        icon: 'sidenav-fleet.svg',
        path: '/fleet',

        items: [
            {
                title: 'Appliances',
                path: '/fleet/appliances',
                condition: IsAllowed('PURE1:read:appliances'),
            },
            {
                title: 'Subscriptions',
                path: '/fleet/subscriptions',
                condition: IsAllowed('PURE1:read:subscription'),
            },
            {
                title: 'Virtual Machines',
                path: '/fleet/topology',
                condition: IsAllowed('PURE1:read:analytic_vmtopology'),
            },
            {
                title: 'Containers',
                path: '/fleet/clusters',
                condition: IsAllowed('PURE1:read:px_cluster'),
            },
        ],
    },
    {
        title: 'ANALYTICS',
        icon: 'sidenav-analytics.svg',
        path: '/analysis',

        items: [
            {
                title: 'Performance',
                path: '/analysis/performance',
                condition: IsAllowed('PURE1:read:analytic_performance'),
            },
            {
                title: 'Capacity',
                path: '/analysis/capacity',
                condition: IsAllowed('PURE1:read:analytic_capacity'),
            },
            {
                title: 'Planning',
                path: '/analysis/planning',
                condition: IsAllowed('PURE1:read:analytic_planning'),
            },
            {
                title: 'Reporting',
                path: '/analysis/reporting',
                condition: AllOf(
                    IsAllowed('PURE1:read:analytic_reporting'),
                    IsFeatureDisabled(FeatureNames.ORG_SWITCHING),
                ),
            },
        ],
    },
    {
        title: 'PROTECTION',
        icon: 'sidenav-protection.svg',
        path: '/protection',

        items: [
            {
                title: 'Snapshots',
                path: '/protection/snapshots',
                condition: IsAllowed('PURE1:read:protection_snapshots'),
            },
            {
                title: 'Periodic',
                path: '/protection/periodic',
                condition: IsAllowed('PURE1:read:protection_periodic'),
            },
            {
                title: 'Continuous',
                path: '/protection/continuous',
                condition: IsAllowed('PURE1:read:protection_continuous'),
            },
            {
                title: 'ActiveCluster',
                path: '/protection/activecluster',
                condition: IsAllowed('PURE1:read:protection_clustering'),
            },
        ],
    },
    {
        title: 'DRaaS',
        icon: 'sidenav-disaster-recovery.svg',
        path: '/draas',
        condition: IsAllowed('PURE1:read:disaster_recovery'),
        options: { showIfEmpty: true },

        items: [
            {
                title: 'Deployments',
                path: '/draas/deployments',
                condition: AllOf(
                    IsFeatureEnabled(FeatureNames.DRAAS_V2_PAGES),
                    IsAllowed('DRAAS:uipage_overview:read'),
                ),
            },
            {
                title: 'Overview',
                path: '/draas/overview',
                condition: IsAllowed('DRAAS:uipage_overview:read'),
            },
            {
                title: 'Assessment',
                path: '/draas/assessment',
                condition: IsFeatureEnabled(FeatureNames.DRAAS_VMA_INTEGRATION),
            },
            {
                title: 'Monitor',
                path: '/draas/monitor',
                condition: SomeOf(
                    IsAllowed('DRAAS:uipage_monitor_recovery:read'),
                    IsAllowed('DRAAS:uipage_monitor_reports:read'),
                    IsAllowed('DRAAS:uipage_monitor_protection:read'),
                ),
            },
            {
                title: 'Setup',
                path: '/draas/setup',
                condition: SomeOf(
                    IsAllowed('DRAAS:uipage_setup_plans:read'),
                    IsAllowed('DRAAS:uipage_setup_groups:read'),
                    IsAllowed('DRAAS:uipage_setup_policies:read'),
                    IsAllowed('DRAAS:uipage_setup_system:read'),
                ),
            },
        ],
    },
    {
        title: 'MESSAGES',
        icon: 'sidenav-messages.svg',
        path: '/messages',

        items: [
            {
                title: 'Alerts',
                path: '/messages/alerts',
                condition: IsAllowed('PURE1:read:alert'),
            },
            {
                title: 'Audit Log',
                path: '/messages/auditlog',
                condition: IsAllowed('PURE1:read:messages_auditlog'),
            },
            {
                title: 'Session Log',
                path: '/messages/sessions',
                condition: IsAllowed('PURE1:read:messages_sessionlog'),
            },
            {
                title: 'Requests',
                path: '/messages/requests',
                condition: IsFeatureEnabled(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH),
            },
        ],
    },
    {
        title: 'SUPPORT',
        path: '/support',
        icon: 'sidenav-support.svg',

        items: [
            {
                title: 'Cases',
                path: '/support',
                condition: IsAllowed('PURE1:read:support_cases'),
            },
        ],
    },
    {
        title: 'ADMINISTRATION',
        icon: 'sidenav-admin.svg',
        path: '/administration',

        items: [
            {
                title: 'Edge Service',
                path: '/administration/edgeservice',
                condition: IsAllowed('PURE1:read:ems'),
            },
            {
                title: 'API Registration',
                path: {
                    type: 'app',
                    appId: 'identity-center',
                    path: '/app/api-keys?source=pure1',
                },
                condition: IsAllowed('PURE1:read:apikey'),
            },
            {
                title: 'SSO/AD',
                path: {
                    type: 'app',
                    appId: 'identity-center',
                    path: '/app/identity-providers?source=pure1',
                },
                condition: AllOf(IsAllowed('PURE1:read:sso'), isIdpAvailable),
            },
            {
                title: 'Users',
                path: {
                    type: 'app',
                    appId: 'identity-center',
                    path: '/app/users?source=pure1',
                },
                condition: IsAllowed('PURE1:read:users'),
            },
            {
                title: 'Views',
                path: '/administration/views',
                condition: AllOf(IsAllowed('PURE1:read:users'), IsFeatureDisabled(FeatureNames.REMOVE_VIEWS)),
            },
            {
                title: 'Patch-2024-01-15',
                path: '/administration/security-patch-fb-2024-01-15',
                condition: AllOf(() => needsSecurityPatch(FeatureNames.SECURITY_PATCH_FB_2024_01_15)),
            },
            {
                title: 'Patch-2025-01-07',
                path: '/administration/security-patch-fb-2025-01-07',
                condition: AllOf(() => needsSecurityPatch(FeatureNames.SECURITY_PATCH_FB_2025_01_07)),
            },
        ],
    },
    {
        title: MENU_SEPARATOR_TITLE,
    },
    {
        title: 'REPORTING',
        path: '/reporting',
        icon: 'sidenav-reporting.svg',
        condition: AllOf(IsAllowed('PURE1:read:analytic_reporting'), IsFeatureEnabled(FeatureNames.ORG_SWITCHING)),
    },
];
