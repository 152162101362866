import { Resource } from '../interfaces/resource';
import { AdditionalRoleAssignment } from './additional-role';
import { Assignment } from './assignment';

export class Group extends Assignment implements Resource {
    id: string;
    name: string;

    additionalRoles: AdditionalRoleAssignment[];

    constructor(group: any) {
        super(group);
        this.id = group.name;
        this.name = group.name;
        this.additionalRoles = (group.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
    }
}
