import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { CapacityConfig } from './capacity-config';

export enum IncidentState {
    Unspecified = 0,
    Open = 1,
    Dismissed = 2,
    Closed = 3,
}

export enum IncidentSource {
    GENERATOR = 'GENERATOR',
    SE = 'SE',
}

/**
 * Used by the backend for specifying recommendation type
 */
export enum RecommendationCode {
    Capacity = 'prorec-capacity-upgrade',
    Controller = 'prorec-controller-upgrade',
    CapCon = 'prorec-capcon-upgrade',
    EolM2X = 'prorec-eol-m2x-sas2nvme',
    Eol6G = 'prorec-eol-6g-upgrade',
    EoL11 = 'prorec-eol-sas2nvme-1-1',
    EoL20 = 'prorec-eol-xr2-2-0',
    LeanSas2NVMe = 'prorec-eol-lean-x-sas2nvme',
    EvergreenOne = 'prorec-eone-commit-expansion',
    Unknown = 'unknown',
}

export const EOL_RECOMMENDATIONS = [
    RecommendationCode.EolM2X,
    RecommendationCode.Eol6G,
    RecommendationCode.EoL11,
    RecommendationCode.EoL20,
    RecommendationCode.LeanSas2NVMe,
];

export function getApplianceRecommendationTooltip(id: string): string {
    const incidentCode = this.incidentMap.get(id).recommendationCode;
    if (incidentCode) {
        if (EOL_RECOMMENDATIONS.includes(incidentCode)) {
            return 'End of Life Recommendation';
        } else if (incidentCode === RecommendationCode.Capacity) {
            return 'Capacity Upgrade Recommendation';
        } else if (incidentCode === RecommendationCode.Controller) {
            return 'Controller Upgrade Recommendation';
        } else if (incidentCode === RecommendationCode.CapCon) {
            return 'Capacity and Controller Upgrade Recommendation';
        } else if (incidentCode === RecommendationCode.EvergreenOne) {
            return 'Reserve Expansion Recommendation';
        }
        return '';
    }
}

/**
 * Used by ampli to for event tracking
 */
export enum AmpliRecTypeStr {
    Controller = 'controller upgrade',
    Capacity = 'capacity upgrade',
    Combined = 'combined upgrade',
    M2X = 'm2x upgrade',
    EoL6G = '6g eol upgrade',
    Eol11 = 'eol 1.1 upgrade',
    Eol20 = 'eol 2.0 upgrade',
    LeanSas2NVMe = 'lean sas to nvme upgrade',
    EvergreenOne = 'evergreen one reserve commit expansion',
}

export enum AmpliRecSourceStr {
    Generator = 'generator',
    SE = 'systems engineer',
}

/** Only includes types that you can make with an SE rec */
export function getAmpliRecTypeForSERec(hasController: boolean, hasCapacity: boolean): AmpliRecTypeStr {
    if (hasController && hasCapacity) {
        return AmpliRecTypeStr.Combined;
    } else if (hasController) {
        return AmpliRecTypeStr.Controller;
    } else if (hasCapacity) {
        return AmpliRecTypeStr.Capacity;
    } else {
        return null;
    }
}

export function isRecThatAllowsSeRecs(
    info: AllPossibleRecommendationDetailTypes,
): info is CapConRecommendationDetails | CapacityRecommendationDetails | ControllerRecommendationDetails {
    return [RecommendationCode.CapCon, RecommendationCode.Capacity, RecommendationCode.Controller].includes(info.type);
}

export function hasCapacityChange(
    info: AllPossibleRecommendationDetailTypes,
): info is
    | CapacityRecommendationDetails
    | CapConRecommendationDetails
    | M2XRecommendationDetails
    | Eol6GRecommendationDetails {
    return 'newUsableTiB' in info;
}

export function hasControllerChange(
    info: AllPossibleRecommendationDetailTypes,
): info is
    | CapConRecommendationDetails
    | ControllerRecommendationDetails
    | M2XRecommendationDetails
    | Eol20RecommendationDetails {
    return 'getRecModelFA' in info;
}

export function isEOLDetails(
    details: AllPossibleRecommendationDetailTypes,
): details is
    | Eol6GRecommendationDetails
    | M2XRecommendationDetails
    | Eol11RecommendationDetails
    | Eol20RecommendationDetails {
    return (
        details.type === RecommendationCode.EolM2X ||
        details.type === RecommendationCode.Eol6G ||
        details.type === RecommendationCode.EoL11 ||
        details.type === RecommendationCode.EoL20
    );
}

export function is6GRecommendation(
    details: AllPossibleRecommendationDetailTypes,
): details is Eol6GRecommendationDetails {
    return details.type === RecommendationCode.Eol6G;
}

export function isEoL11Recommendation(
    details: AllPossibleRecommendationDetailTypes,
): details is Eol11RecommendationDetails {
    return details.type === RecommendationCode.EoL11;
}

export function isEoL20Recommendation(
    details: AllPossibleRecommendationDetailTypes,
): details is Eol20RecommendationDetails {
    return details.type === RecommendationCode.EoL20;
}

export function isM2XRecommendation(
    details: AllPossibleRecommendationDetailTypes,
): details is M2XRecommendationDetails {
    return details.type === RecommendationCode.EolM2X;
}

export function isLeanSas2NvmeDetails(
    details: AllPossibleRecommendationDetailTypes,
): details is LeanSas2NvmeRecommendationDetails {
    return details.type === RecommendationCode.LeanSas2NVMe;
}

export function isEvergreenOneDetails(
    details: AllPossibleRecommendationDetailTypes,
): details is EvergreenOneRecommendationDetails {
    return details.type === RecommendationCode.EvergreenOne;
}

export function hasSparklineDetails(
    details: AllPossibleRecommendationDetailTypes,
): details is DetailsWithSparklineCapacityInfo {
    return [RecommendationCode.Capacity, RecommendationCode.CapCon, RecommendationCode.EvergreenOne].includes(
        details.type,
    );
}

export function hasCapacityDetails(details: AllPossibleRecommendationDetailTypes): details is DetailsWithCapacityInfo {
    return [RecommendationCode.Capacity, RecommendationCode.CapCon].includes(details.type);
}

export function getRecommendedSku(incident: Incident): String {
    if (isEOLDetails(incident.additionalInformation)) {
        return incident.additionalInformation.recommendedSku;
    }
    return null;
}

// Only visible for testing
export const TIB_BYTES = 2 ** 40;

export type DetailsWithCapacityInfo = CapacityRecommendationDetails | CapConRecommendationDetails;
export type DetailsWithSparklineCapacityInfo =
    | CapacityRecommendationDetails
    | CapConRecommendationDetails
    | EvergreenOneRecommendationDetails;
export type DetailsWithControllerInfo = ControllerRecommendationDetails | CapConRecommendationDetails;

export type AllPossibleRecommendationDetailTypes =
    | CapConRecommendationDetails
    | CapacityRecommendationDetails
    | ControllerRecommendationDetails
    | M2XRecommendationDetails
    | Eol6GRecommendationDetails
    | Eol11RecommendationDetails
    | Eol20RecommendationDetails
    | LeanSas2NvmeRecommendationDetails
    | UnknownRecommendationDetails
    | EvergreenOneRecommendationDetails;

export type CreatorInfo = {
    name: string;
    email: string;
};
export type PartnerAccount = {
    id: string;
    name: string;
};

export type IncidentDTO = {
    id: string;
    org_id: number;
    appliance_id: string;
    problem: string;
    recommendation: string;
    recommendation_code: string;
    state: number;
    incident_source: IncidentSource;
    additional_information:
        | CapacityAdditionalInformationDTO
        | ControllerAdditionalInformationDTO
        | CapConAddtionalInformationDTO;
};

export type CapacityAdditionalInformationDTO = {
    array_name: string;
    as_of: number;
    emails: string[];
    proposal_info?: ProposalInfoDTO;

    // capacity
    in_days: number;
    current_usable_tib: number;
    new_usable_tib: number;
    current_installed_tb: number;
    new_installed_tb: number;
    capacity_config: CapacityConfig;
    capacity_threshold: number;
    capacity_timeseries: number[][];

    current_model: string;
};

export type ControllerAdditionalInformationDTO = {
    array_name: string;
    as_of: number;
    emails: string[];
    proposal_info: ProposalInfoDTO;

    // controller
    current_model: string;
    recommended_model: string;
    load_timeseries: number[][];
};

export type ProposalInfoDTO = {
    creator_info: { name: string; email: string };
    creator_message: string;
    partner_account?: { id: string; name: string };
};

export type CapConAddtionalInformationDTO = ControllerAdditionalInformationDTO & CapacityAdditionalInformationDTO;

export class Incident implements Resource {
    static makePreviewCapacityIncident(info: {
        arrayName: string;
        applianceId: string;
        orgId: number;
        emails: string[];
        asOf: number;
        timerangeDays: number;
        currentRawTB: number;
        newRawTB: number;
        currentUsableTiB: number;
        newUsableTiB: number;
        capacityTimeseries: number[][];
        capacityConfig: CapacityConfig;
        creatorName: string;
        creatorEmail: string;
        creatorMessage?: string;
        controller: string;
    }): Incident {
        return new Incident({
            recommendation_code: RecommendationCode.Capacity,
            incident_source: IncidentSource.SE,
            appliance_id: info.applianceId,
            org_id: info.orgId,
            additional_information: {
                array_name: info.arrayName,
                as_of: info.asOf,
                emails: info.emails,

                // capacity
                in_days: info.timerangeDays,
                current_usable_tib: info.currentUsableTiB,
                new_usable_tib: info.newUsableTiB,
                current_installed_tb: info.currentRawTB,
                new_installed_tb: info.newRawTB,
                sparkline: info.capacityTimeseries,
                capacity_timeseries: info.capacityTimeseries,
                capacity_config: info.capacityConfig,

                proposal_info: {
                    creator_info: { name: info.creatorName, email: info.creatorEmail },
                    creator_message: info.creatorMessage ?? '',
                    partner_account: { id: '', name: '' },
                },

                // Provided so that backend can match datapacks
                current_model: info.controller,
            },
        });
    }

    static makePreviewControllerIncident(info: {
        arrayName: string;
        applianceId: string;
        orgId: number;
        emails: string[];
        asOf: number;
        timerangeDays: number;
        oldController: string;
        newController: string;
        loadTimeseries: number[][];
        creatorName: string;
        creatorEmail: string;
        creatorMessage?: string;
    }): Incident {
        return new Incident({
            recommendation_code: RecommendationCode.Controller,
            incident_source: IncidentSource.SE,
            appliance_id: info.applianceId,
            org_id: info.orgId,
            additional_information: {
                array_name: info.arrayName,
                as_of: info.asOf,
                emails: info.emails,

                // controller
                current_model: info.oldController,
                recommended_model: info.newController,
                load_timeseries: info.loadTimeseries,
                proposal_info: {
                    creator_info: { name: info.creatorName, email: info.creatorEmail },
                    creator_message: info.creatorMessage ?? '',
                    partner_account: { id: '', name: '' },
                },
            },
        });
    }
    static makePreviewCapConIncident(info: {
        arrayName: string;
        applianceId: string;
        orgId: number;
        emails: string[];
        asOf: number;
        timerangeDays: number;
        currentRawTB: number;
        newRawTB: number;
        currentUsableTiB: number;
        newUsableTiB: number;
        capacityTimeseries: number[][];
        capacityConfig: CapacityConfig;
        oldController: string;
        newController: string;
        loadTimeseries: number[][];
        creatorName: string;
        creatorEmail: string;
        creatorMessage?: string;
    }): Incident {
        return new Incident({
            recommendation_code: RecommendationCode.CapCon,
            incident_source: IncidentSource.SE,
            appliance_id: info.applianceId,
            org_id: info.orgId,
            additional_information: {
                array_name: info.arrayName,
                as_of: info.asOf,
                emails: info.emails,

                // capacity
                in_days: info.timerangeDays,
                current_usable_tib: info.currentUsableTiB,
                new_usable_tib: info.newUsableTiB,
                current_installed_tb: info.currentRawTB,
                new_installed_tb: info.newRawTB,
                sparkline: info.capacityTimeseries,
                capacity_timeseries: info.capacityTimeseries,
                capacity_config: info.capacityConfig,

                // controller
                current_model: info.oldController,
                recommended_model: info.newController,
                load_timeseries: info.loadTimeseries,
                proposal_info: {
                    creator_info: { name: info.creatorName, email: info.creatorEmail },
                    creator_message: info.creatorMessage ?? '',
                    partner_account: { id: '', name: '' },
                },
            },
        });
    }

    id: string;
    name: string; // Left empty, but needed to implement `Resource`
    startTime: moment.Moment;
    endTime?: moment.Moment;
    dismissed: boolean;
    orgId: number;
    applianceId: string;
    problem: string;
    recommendation: string;
    state: IncidentState;
    recommendationCode: RecommendationCode;
    incidentSource: IncidentSource;

    /**
     * Information sepcific to the incident type. The `additionalInformation.type` property as can be used to show
     * typescript which additional information is used.
     */
    additionalInformation: AllPossibleRecommendationDetailTypes;

    constructor(json: any) {
        this.id = json.id;
        this.state = json.state;
        this.startTime = moment(json.start_time);
        this.endTime = json.end_time ? moment(json.end_time) : null;
        this.orgId = json.org_id;
        this.applianceId = json.appliance_id;
        this.problem = json.problem;
        this.recommendation = json.recommendation;
        this.recommendationCode = json.recommendation_code;
        // fallback for legacy incidents without incident_source
        this.incidentSource = json.incident_source ?? IncidentSource.GENERATOR;

        if (!json.additional_information) {
            console.error(
                `missing additional_information for incident ${this.id}, recommendation code: ${this.recommendationCode}`,
            );
            this.additionalInformation = new UnknownRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.Capacity) {
            this.additionalInformation = new CapacityRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.Controller) {
            this.additionalInformation = new ControllerRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.CapCon) {
            this.additionalInformation = new CapConRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.EolM2X) {
            this.additionalInformation = new M2XRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.Eol6G) {
            this.additionalInformation = new Eol6GRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.EoL11) {
            this.additionalInformation = new Eol11RecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.EoL20) {
            this.additionalInformation = new Eol20RecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.LeanSas2NVMe) {
            this.additionalInformation = new LeanSas2NvmeRecommendationDetails(json.additional_information);
        } else if (this.recommendationCode === RecommendationCode.EvergreenOne) {
            this.additionalInformation = new EvergreenOneRecommendationDetails(json.additional_information);
        } else {
            console.error(
                `unknown recommendation code for incident ${this.id}, recommendation code: ${this.recommendationCode}`,
            );
            this.additionalInformation = new UnknownRecommendationDetails(json.additional_information);
        }
    }

    getDTO(): IncidentDTO {
        if (!isRecThatAllowsSeRecs(this.additionalInformation)) {
            console.error(
                'Attempted to get DTO from rec which is not used for SE recs: ' + this.additionalInformation.type,
            );
            return null;
        }
        return {
            id: this.id,
            org_id: this.orgId,
            appliance_id: this.applianceId,
            recommendation_code: this.recommendationCode,
            problem: '',
            recommendation: '',
            state: this.state,
            incident_source: this.incidentSource,
            additional_information: this.additionalInformation.getDTO(),
        };
    }

    // Check if the incident has additional information for a controller recommendation
    hasControllerAdditionalInformation(): boolean {
        return [
            RecommendationCode.Controller,
            RecommendationCode.CapCon,
            RecommendationCode.EolM2X,
            RecommendationCode.EoL20,
        ].includes(this.additionalInformation.type);
    }

    isCapacityRecommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.Capacity;
    }

    isControllerRecommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.Controller;
    }

    isCapConRecommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.CapCon;
    }

    isM2XRecommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.EolM2X;
    }

    is6GRecommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.Eol6G;
    }

    isEoL11Recommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.EoL11;
    }

    isEoL20Recommendation(): boolean {
        return this.additionalInformation?.type === RecommendationCode.EoL20;
    }

    isLeanSas2NvmeRecommendation(): boolean {
        return this.additionalInformation.type === RecommendationCode.LeanSas2NVMe;
    }

    isEvergreenOneRecommendation(): boolean {
        return this.additionalInformation.type === RecommendationCode.EvergreenOne;
    }

    isUnknownRecommendation(): boolean {
        return this.additionalInformation.type === RecommendationCode.Unknown;
    }

    isEOLRecommendation(): boolean {
        return (
            this.isM2XRecommendation() ||
            this.is6GRecommendation() ||
            this.isEoL11Recommendation() ||
            this.isEoL20Recommendation()
        );
    }

    isSeRec(): boolean {
        return this.incidentSource === IncidentSource.SE;
    }

    getSeName(): string {
        if (!isRecThatAllowsSeRecs(this.additionalInformation)) {
            return null;
        }
        return this.additionalInformation.proposalInfo?.creatorInfo.name ?? null;
    }

    getSeMessage(): string {
        if (!isRecThatAllowsSeRecs(this.additionalInformation)) {
            return null;
        }
        return this.additionalInformation?.proposalInfo?.creatorMessage ?? null;
    }

    getCapConDetails(): CapConRecommendationDetails {
        if (this.isCapConRecommendation()) {
            return <CapConRecommendationDetails>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get capcon details from non-capcon recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getCapacityDetails(): DetailsWithCapacityInfo {
        if (hasCapacityDetails(this.additionalInformation)) {
            return this.additionalInformation;
        } else {
            console.error(
                'Attempted to get capacity details from non-capacity recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getCapacitySparklineDetails(): DetailsWithSparklineCapacityInfo {
        if (hasSparklineDetails(this.additionalInformation)) {
            return this.additionalInformation;
        } else {
            console.error(
                'Attempted to get sparkline details from non-sparkline recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getEvergreenOneDetails(): EvergreenOneRecommendationDetails {
        if (this.isEvergreenOneRecommendation()) {
            return <EvergreenOneRecommendationDetails>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get evergreen one details from non-evergreen one recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getControllerDetails(): DetailsWithControllerInfo {
        if (this.hasControllerAdditionalInformation()) {
            return <DetailsWithControllerInfo>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get controller details from non-controller recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getM2XDetails(): M2XRecommendationDetails {
        if (this.isM2XRecommendation()) {
            return <M2XRecommendationDetails>this.additionalInformation;
        } else {
            console.error('Attempted to get m2x details from non-m2x recommendation: ', this.getRecommendationType());
            return null;
        }
    }

    get6GDetails(): Eol6GRecommendationDetails {
        if (this.is6GRecommendation()) {
            return <Eol6GRecommendationDetails>this.additionalInformation;
        } else {
            console.error('Attempted to get 6g details from non-6g recommendation: ', this.getRecommendationType());
            return null;
        }
    }

    getEoL1Dot1Details(): Eol11RecommendationDetails {
        if (this.isEoL11Recommendation()) {
            return <Eol11RecommendationDetails>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get EoL1.1 details from non-EoL1.1 recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getEoL2Dot0Details(): Eol20RecommendationDetails {
        if (this.isEoL20Recommendation()) {
            return <Eol20RecommendationDetails>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get EoL2.0 details from non-EoL2.0 recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getLeanSas2NvmeDetails(): LeanSas2NvmeRecommendationDetails {
        if (this.isLeanSas2NvmeRecommendation()) {
            return <LeanSas2NvmeRecommendationDetails>this.additionalInformation;
        } else {
            console.error(
                'Attempted to get trade up sas to NVMe details from a different recommendation: ',
                this.getRecommendationType(),
            );
            return null;
        }
    }

    getEntityName(): string {
        return this.additionalInformation.entityName;
    }

    getRecommendationType(): RecommendationCode {
        return this.additionalInformation.type;
    }

    getAmpliRecType(): AmpliRecTypeStr {
        switch (this.getRecommendationType()) {
            case RecommendationCode.Capacity:
                return AmpliRecTypeStr.Capacity;
            case RecommendationCode.Controller:
                return AmpliRecTypeStr.Controller;
            case RecommendationCode.CapCon:
                return AmpliRecTypeStr.Combined;
            case RecommendationCode.EolM2X:
                return AmpliRecTypeStr.M2X;
            case RecommendationCode.LeanSas2NVMe:
                return AmpliRecTypeStr.LeanSas2NVMe;
            case RecommendationCode.EvergreenOne:
                return AmpliRecTypeStr.EvergreenOne;
            case RecommendationCode.Eol6G:
                return AmpliRecTypeStr.EoL6G;
            case RecommendationCode.EoL11:
                return AmpliRecTypeStr.Eol11;
            case RecommendationCode.EoL20:
                return AmpliRecTypeStr.Eol20;
            default:
                return null;
        }
    }

    getAmpliRecSourceString(): AmpliRecSourceStr {
        switch (this.incidentSource) {
            case IncidentSource.GENERATOR:
                return AmpliRecSourceStr.Generator;
            case IncidentSource.SE:
                return AmpliRecSourceStr.SE;
            default:
                return null;
        }
    }

    setSeMessage(text: string): void {
        if (
            this.id ||
            !isRecThatAllowsSeRecs(this.additionalInformation) ||
            this.incidentSource !== IncidentSource.SE ||
            !this.additionalInformation.proposalInfo
        ) {
            // Ensure this isn't called on incidents which are already created on backend (id defined) or for
            // incidents that arent SE generated (m2x, not correct source or missing proposal info)
            console.error('Attempted to set message for SE rec for an invalid incident');
            return;
        }
        if (text == null) {
            // TODO remove once https://jira.purestorage.com/browse/CLOUD-86717 complete
            return;
        }
        this.additionalInformation.proposalInfo.creatorMessage = text;
    }
}

export type CapacityRecommendationShelfOrChassis = {
    /** Chassis/shelf type (eg SAS, directFlash, 12G_SAS, DFMc) */
    type: string;

    datapacks: {
        /** Current datapack size. Will be null for a datapack recommended to be added. */
        currentTB?: number;
        /** Recommended datapack size. Will be null if no change is recommended for this datapack. */
        recommendedTB?: number;
    }[];
};

export interface RecommendationDetails {
    type: RecommendationCode;
    /** Refers to either an array or license name */
    entityName: string;
    emails: string[];
}

export interface SERecRecommendation {
    proposalInfo?: ProposalInfo;
    /** These "data transfer objects" are used to post a new SE rec from the frontend */
    getDTO(): CapacityAdditionalInformationDTO | ControllerAdditionalInformationDTO | CapConAddtionalInformationDTO;
}

export interface RecommendationDetailsWithCapacity {
    currentInstalledTB: number;
    currentUsableTiB: number;

    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
}

export class CapacityRecommendationDetails
    implements RecommendationDetails, SERecRecommendation, RecommendationDetailsWithCapacity
{
    readonly type = RecommendationCode.Capacity;
    entityName: string;
    emails: string[];
    proposalInfo?: ProposalInfo;

    /** Capacity threshold for automatically generated recommendations ie. 80. This means a recommendation is raised when
     * the array is over 80% full. */
    capacityThreshold: number;
    /** Eg: "will be 80% full in X days" */
    timerangeDays: number;

    currentInstalledTB: number;
    currentUsableTiB: number;

    newInstalledTB: number;
    newUsableTiB: number;

    sparkline: {
        /** Any timestamp up to "asOfTime" is historic. Last point is projection that goes out "inDays" days. Timestamps do not need to be equal steps apart. */
        timeseries: [number, number][];

        /** Which timestamp in the timeseries represents "now". Any point after that is a projection. */
        asOfTime: number;
    };

    /** Full capacity timeseries. Value represented as percent full. Used for the chart in the recommendation details modal. */
    capacityTimeseries: [number, number][];

    capacityConfig: CapacityConfig;

    /** Included for preview incidents for backend to be able to query for allowable datapacks */
    currentModel?: string;

    constructor(json: any) {
        if (json.proposal_info) {
            this.proposalInfo = new ProposalInfo(json.proposal_info);
        }
        this.entityName = json.array_name;
        this.timerangeDays = json.in_days;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.sparkline = { timeseries: json.sparkline, asOfTime: json.as_of };
        this.capacityTimeseries = json.capacity_timeseries;
        this.capacityConfig = json.capacity_config;
        this.capacityThreshold = json.capacity_threshold ?? json.percent_full ?? 0;
        // For preview incidents only
        this.currentModel = convertModelWtihFAToSlash(json.current_model);
        this.emails = json.emails;
    }

    getDTO(): CapacityAdditionalInformationDTO {
        return {
            array_name: this.entityName,
            as_of: this.sparkline.asOfTime,
            proposal_info: this.proposalInfo?.getDTO(),
            emails: this.emails,

            capacity_config: this.capacityConfig,
            capacity_timeseries: this.capacityTimeseries,
            current_installed_tb: this.currentInstalledTB,
            current_usable_tib: this.currentUsableTiB,
            new_installed_tb: this.newInstalledTB,
            new_usable_tib: this.newUsableTiB,
            in_days: this.timerangeDays,
            capacity_threshold: this.capacityThreshold,
            current_model: this.currentModel,
        };
    }

    getSparklineCurrentCapacity(): number {
        if (!this.sparkline?.asOfTime) {
            return 1;
        }
        const currentPercentIndex = this.sparkline.timeseries.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentPercentIndex < 0) {
            return 1;
        }
        return this.sparkline.timeseries[currentPercentIndex][1];
    }

    getSparklineProjectedCapacity(): number {
        const series = this.sparkline.timeseries;
        return series[series.length - 1][1];
    }

    getSparklineMaxCapacity(): number {
        return getSeriesMaximum(this.sparkline.timeseries)[1];
    }

    getCurrentPercentCapacity(): string {
        if (!this.capacityTimeseries || !this.sparkline?.asOfTime) {
            return '';
        }
        const currentPercentIndex = this.capacityTimeseries.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentPercentIndex < 0) {
            return '1';
        }
        return percentify(this.capacityTimeseries[currentPercentIndex][1]);
    }

    getProjectedPercentCapacity(): string {
        const series = this.capacityTimeseries;
        return percentify(series[series.length - 1][1]);
    }
}

export class ControllerRecommendationDetails implements RecommendationDetails, SERecRecommendation {
    readonly type = RecommendationCode.Controller;
    entityName: string;
    emails: string[];
    proposalInfo?: ProposalInfo;
    currentModel: string;
    recommendedModel: string;
    /** Load timeseries. Used for the chart in the recommendation details modal. */
    loadTimeseries: [number, number][];
    /** Which timestamp in the timeseries represents the last day of historical data. Any point after that is a projection. */
    asOfTime: number;

    constructor(json: any) {
        if (json.proposal_info) {
            this.proposalInfo = new ProposalInfo(json.proposal_info);
        }
        this.entityName = json.array_name;
        this.currentModel = convertModelWtihFAToSlash(json.current_model);
        this.recommendedModel = convertModelWtihFAToSlash(json.recommended_model);
        this.loadTimeseries = json.load_timeseries;
        this.asOfTime = json.as_of;
        this.emails = json.emails;
    }

    getDTO(): ControllerAdditionalInformationDTO {
        return {
            array_name: this.entityName,
            as_of: this.asOfTime,
            proposal_info: this.proposalInfo?.getDTO(),
            emails: this.emails,

            current_model: this.currentModel,
            recommended_model: this.recommendedModel,
            load_timeseries: this.loadTimeseries,
        };
    }

    getHighestPercentLoad(): string {
        return percentify(getSeriesMaximum(this.loadTimeseries, this.asOfTime)[1]);
    }

    /**
     * Put "FA-" in front of model to be compatible with how models are specified for simulations
     * @returns recommended model with FA prefix
     */
    getRecModelFA(): string {
        return 'FA-' + this.recommendedModel.slice(2);
    }

    getCurrModelNoPrefix(): string {
        return this.currentModel.slice(2);
    }
}

export class CapConRecommendationDetails
    implements RecommendationDetails, SERecRecommendation, RecommendationDetailsWithCapacity
{
    readonly type = RecommendationCode.CapCon;
    entityName: string;
    emails: string[];
    proposalInfo?: ProposalInfo;
    // Controller
    currentModel: string;
    recommendedModel: string;
    /** Load timeseries. Used for the chart in the recommendation details modal. */
    loadTimeseries: [number, number][];
    /** Which timestamp in the timeseries represents the last day of historical data. Any point after that is a projection. */
    asOfTime: number;

    /** Capacity threshold for automatically generated recommendations ie. 80. This means a recommendation is raised when
     * the array is over 80% full. */
    capacityThreshold: number;
    /** Eg: "will be 80% full in X days" */
    timerangeDays: number;

    currentInstalledTB: number;
    currentUsableTiB: number;

    newInstalledTB: number;
    newUsableTiB: number;

    sparkline: {
        /** Any timestamp up to "asOfTime" is historic. Last point is projection that goes out "timerangeDays" days. Timestamps do not need to be equal steps apart. */
        timeseries: [number, number][];

        /** Which timestamp in the timeseries represents "now". Any point after that is a projection. */
        asOfTime: number;
    };

    /** Full capacity timeseries. Value represented as percent full. Used for the chart in the recommendation details modal. */
    capacityTimeseries: [number, number][];

    capacityConfig: CapacityConfig;

    constructor(json: any) {
        this.entityName = json.array_name;
        if (json.proposal_info) {
            this.proposalInfo = new ProposalInfo(json.proposal_info);
        }

        // Capacity info
        this.timerangeDays = json.in_days;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.sparkline = { timeseries: json.sparkline, asOfTime: json.as_of };
        this.capacityTimeseries = json.capacity_timeseries;
        this.capacityConfig = json.capacity_config;
        // Clean up when backend updates to using capacity threshold https://jira.purestorage.com/browse/CLOUD-87586
        this.capacityThreshold = json.capacity_threshold ?? json.percent_full ?? 0;

        // Controller info
        this.currentModel = convertModelWtihFAToSlash(json.current_model);
        this.recommendedModel = convertModelWtihFAToSlash(json.recommended_model);
        this.loadTimeseries = json.load_timeseries;
        this.asOfTime = json.as_of;
        this.emails = json.emails;
    }

    getDTO(): CapConAddtionalInformationDTO {
        return {
            array_name: this.entityName,
            as_of: this.sparkline.asOfTime,
            proposal_info: this.proposalInfo?.getDTO(),
            emails: this.emails,

            capacity_config: this.capacityConfig,
            capacity_timeseries: this.capacityTimeseries,
            current_installed_tb: this.currentInstalledTB,
            current_usable_tib: this.currentUsableTiB,
            new_installed_tb: this.newInstalledTB,
            new_usable_tib: this.newUsableTiB,
            in_days: this.timerangeDays,
            capacity_threshold: this.capacityThreshold,

            current_model: this.currentModel,
            recommended_model: this.recommendedModel,
            load_timeseries: this.loadTimeseries,
        };
    }

    getSparklineCurrentCapacity(): number {
        if (!this.sparkline?.asOfTime) {
            return 1;
        }
        const currentPercentIndex = this.sparkline.timeseries.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentPercentIndex < 0) {
            return 1;
        }
        return this.sparkline.timeseries[currentPercentIndex][1];
    }

    getSparklineProjectedCapacity(): number {
        const series = this.sparkline.timeseries;
        return series[series.length - 1][1];
    }

    getSparklineMaxCapacity(): number {
        return getSeriesMaximum(this.sparkline.timeseries)[1];
    }

    getCurrentPercentCapacity(): string {
        if (!this.capacityTimeseries || !this.sparkline?.asOfTime) {
            return '';
        }
        const currentPercentIndex = this.capacityTimeseries.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentPercentIndex < 0) {
            return '1';
        }
        return percentify(this.capacityTimeseries[currentPercentIndex][1]);
    }

    getProjectedPercentCapacity(): string {
        const series = this.capacityTimeseries;
        return percentify(series[series.length - 1][1]);
    }

    getHighestPercentLoad(): string {
        return (getSeriesMaximum(this.loadTimeseries, this.asOfTime)[1] * 100).toFixed(0) + '%';
    }

    /**
     * Put "FA-" in front of model to be compatible with how models are specified for simulations
     * @returns recommended model with FA prefix
     */
    getRecModelFA(): string {
        return 'FA-' + this.recommendedModel.slice(2);
    }

    getCurrModelNoPrefix(): string {
        return this.currentModel.slice(2);
    }
}

export class M2XRecommendationDetails implements RecommendationDetails, RecommendationDetailsWithCapacity {
    readonly type = RecommendationCode.EolM2X;
    entityName: string;
    emails: string[];
    currentModel: string;
    recommendedModel: string;
    recommendedSku: string;
    currentInstalledTB: number;
    currentUsableTiB: number;
    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
    eolDate: moment.Moment;
    constructor(json: any) {
        this.entityName = json.array_name;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.capacityConfig = json.capacity_config;
        this.recommendedSku = json.recommended_sku;
        this.currentModel = convertModelWtihFAToSlash(json.current_model);
        this.recommendedModel = convertModelWtihFAToSlash(json.recommended_model);
        this.eolDate = moment(json.eol_datetime);
    }

    getRecModelFA(): string {
        return 'FA-' + this.recommendedModel.slice(2);
    }

    getCurrModelNoPrefix(): string {
        return this.currentModel.slice(2);
    }
}

export class Eol6GRecommendationDetails implements RecommendationDetails, RecommendationDetailsWithCapacity {
    readonly type = RecommendationCode.Eol6G;
    currentInstalledTB: number;
    currentUsableTiB: number;
    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
    entityName: string;
    emails: string[];
    recommendedSku: string;
    eolDate: moment.Moment;

    constructor(json: any) {
        this.entityName = json.array_name;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.capacityConfig = json.capacity_config;
        this.recommendedSku = json.recommended_sku;
        this.eolDate = moment(json.eol_datetime);
    }
}

export class Eol11RecommendationDetails implements RecommendationDetails, RecommendationDetailsWithCapacity {
    readonly type = RecommendationCode.EoL11;
    currentInstalledTB: number;
    currentUsableTiB: number;
    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
    entityName: string;
    emails: string[];
    recommendedSku: string;
    eolDate: moment.Moment;

    constructor(json: any) {
        this.entityName = json.array_name;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.capacityConfig = json.capacity_config;
        this.recommendedSku = json.recommended_sku;
        this.eolDate = moment(json.eol_datetime);
    }
}

export class Eol20RecommendationDetails implements RecommendationDetails, RecommendationDetailsWithCapacity {
    readonly type = RecommendationCode.EoL20;
    currentModel: string;
    recommendedModel: string;
    currentInstalledTB: number;
    currentUsableTiB: number;
    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
    entityName: string;
    emails: string[];
    recommendedSku: string;
    eolDate: moment.Moment;

    constructor(json: any) {
        this.entityName = json.array_name;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.capacityConfig = json.capacity_config;
        this.recommendedSku = json.recommended_sku;
        this.currentModel = convertModelWtihFAToSlash(json.current_model);
        this.recommendedModel = convertModelWtihFAToSlash(json.recommended_model);
        this.eolDate = moment(json.eol_datetime);
    }

    /**
     * Put "FA-" in front of model to be compatible with how models are specified for simulations
     * @returns recommended model with FA prefix
     */
    getRecModelFA(): string {
        return 'FA-' + this.recommendedModel.slice(2);
    }

    getCurrModelNoPrefix(): string {
        return this.currentModel.slice(2);
    }
}

export class LeanSas2NvmeRecommendationDetails implements RecommendationDetails, RecommendationDetailsWithCapacity {
    readonly type = RecommendationCode.LeanSas2NVMe;
    currentInstalledTB: number;
    currentUsableTiB: number;
    newInstalledTB: number;
    newUsableTiB: number;
    capacityConfig: CapacityConfig;
    entityName: string;
    emails: string[];
    recommendedSku: string;

    constructor(json: any) {
        this.entityName = json.array_name;
        this.currentInstalledTB = json.current_installed_tb;
        this.currentUsableTiB = json.current_usable_tib;
        this.newInstalledTB = json.new_installed_tb;
        this.newUsableTiB = json.new_usable_tib;
        this.capacityConfig = json.capacity_config;
        this.recommendedSku = json.recommended_sku;
    }
}

export class EvergreenOneRecommendationDetails implements RecommendationDetails {
    readonly type = RecommendationCode.EvergreenOne;
    entityName: string;
    emails: string[];

    /** Capacity threshold for automatically generated recommendations ie. 80. This means a recommendation is raised when
     * the array is over 80% full. */
    capacityThreshold: number;

    timerangeDays: number;

    /** Eg: "is projected to be 100% of reserve in X days" */
    currentDaysToOnDemand: number;
    /** Eg: "after recommended increase, is projected to be 100% of reserve in X days" */
    recommendedDaysToOnDemand: number;

    currentReserveTiB: number;
    recommendedReserveTiB: number;

    serviceTier: string;

    sparkline: {
        /** Any timestamp up to "asOfTime" is historic. Last point is projection that goes out "timerangeDays" days. Timestamps do not need to be equal steps apart. */
        timeseries: [number, number][];

        /** Which timestamp in the timeseries represents "now". Any point after that is a projection. */
        asOfTime: number;
    };

    /** Full capacity timeseries. Used for the chart in the recommendation details modal. */
    capacityTimeseriesTib: [number, number][];

    constructor(json: any) {
        this.entityName = json.license_name;
        this.emails = json.emails;
        this.capacityThreshold = json.capacity_threshold ?? 0;
        this.timerangeDays = json.in_days;

        this.serviceTier = json.current_license_type;
        this.currentDaysToOnDemand = json.days_to_exceed_current_reserve;
        this.recommendedDaysToOnDemand = json.days_to_exceed_projected_reserve;
        this.currentReserveTiB = json.current_reserve_commit_tib;
        this.recommendedReserveTiB = json.recommended_reserve_commit_tib;

        const asOf = moment(json.as_of);

        // Sparkline is shown as a percentage
        this.sparkline = {
            timeseries: divideTimeseries(
                truncateTimeseries(json.sparkline, this.timerangeDays, asOf),
                json.current_reserve_commit,
            ),
            asOfTime: json.as_of,
        };
        this.capacityTimeseriesTib = divideTimeseries(
            truncateTimeseries(json.capacity_timeseries, this.timerangeDays, asOf),
            TIB_BYTES,
        );
    }

    getSparklineCurrentCapacity(): number {
        if (!this.sparkline?.asOfTime) {
            return 1;
        }
        const currentPercentIndex = this.sparkline.timeseries.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentPercentIndex < 0) {
            return 1;
        }
        return this.sparkline.timeseries[currentPercentIndex][1];
    }

    getSparklineProjectedCapacity(): number {
        const series = this.sparkline.timeseries;
        return series[series.length - 1][1];
    }

    getSparklineMaxCapacity(): number {
        return getSeriesMaximum(this.sparkline.timeseries)[1];
    }

    getProjectedPercentCapacity(): string {
        const series = this.capacityTimeseriesTib;
        return percentify(series[series.length - 1][1] / this.currentReserveTiB);
    }

    getCurrentPercentCapacity(): string {
        const currentUsagePercent = this.getCurrentUsagePercent();
        if (currentUsagePercent === null) {
            return '';
        }
        return percentify(currentUsagePercent);
    }

    getRecommendedPercentCapacity(): string {
        const currentUsagePercent = this.getCurrentUsagePercent();
        if (currentUsagePercent === null) {
            return '';
        }
        const newUsagePercent = (this.currentReserveTiB * currentUsagePercent) / this.recommendedReserveTiB;
        return percentify(newUsagePercent);
    }

    private getCurrentUsagePercent(): number {
        if (!this.capacityTimeseriesTib || !this.sparkline?.asOfTime) {
            return null;
        }
        const currentTibIndex = this.capacityTimeseriesTib.findIndex(
            dataPoint => dataPoint[0] > this.sparkline.asOfTime - 1,
        );
        if (currentTibIndex < 0) {
            return null;
        }
        return this.capacityTimeseriesTib[currentTibIndex][1] / this.currentReserveTiB;
    }
}

export class UnknownRecommendationDetails implements RecommendationDetails {
    readonly type = RecommendationCode.Unknown;
    entityName: string;
    emails: string[];
    constructor(json: any) {
        this.entityName = json?.array_name ?? 'NO NAME AVAILABLE';
    }
}

export class ProposalInfo {
    creatorInfo: CreatorInfo;
    creatorMessage?: string;
    partnerAccount?: PartnerAccount;

    constructor(json: any) {
        this.creatorInfo = {
            name: json.creator_info?.name,
            email: json.creator_info?.email,
        };
        // Ignore falsy or empty messages
        // Remove fallback to empty string when https://jira.purestorage.com/browse/CLOUD-86717 complete
        this.creatorMessage = json.creator_message ?? '';

        if (json.partner_account) {
            this.partnerAccount = {
                id: json.partner_account.id,
                name: json.partner_account.name,
            };
        }
    }

    getDTO(): ProposalInfoDTO {
        const { email, name } = this.creatorInfo;
        let res: ProposalInfoDTO = {
            creator_info: { email, name },
            creator_message: this.creatorMessage ?? null,
        };
        if (this.partnerAccount) {
            const { id, name: partnerName } = this.partnerAccount;
            res = { ...res, partner_account: { id, name: partnerName } };
        }
        return res;
    }
}

function percentify(n: number): string {
    return Math.round(n * 100) + '%';
}

function convertModelWtihFAToSlash(model: string): string {
    return model?.replace(/^FA-/, '//');
}

function getSeriesMaximum(series: [number, number][], asOfTime = 0): [number, number] {
    return series.reduce((max, curr) => (curr[0] > asOfTime && max[1] < curr[1] ? curr : max), [0, 0]);
}

/** Truncates timeseries to +/- timerangeDays */
function truncateTimeseries(
    timeseries: [number, number][],
    timerangeDays: number,
    asOf: moment.Moment,
): [number, number][] {
    const min = asOf.clone().subtract(timerangeDays, 'days');
    const max = asOf.clone().add(timerangeDays, 'days');
    return timeseries.filter(([ts, _]) => moment(ts).isBetween(min, max));
}

function divideTimeseries(timeseries: [number, number][], by: number): [number, number][] {
    return timeseries.map(([ts, y]) => [ts, y / by]);
}

export const OPEN_IN_PLANNER_KEY = 'open-in-planning';
/**
 * Passed through storage service to trigger the planning page to open a the modify hardware
 * modal for the listed applianceId. If incidentId is defined, the incident will be fetched by and set by
 * the forecast-view page. Otherwise it will be left null
 */
export type OpenInPlannerInfo = {
    incident?: Incident;
    applianceId: string;
    incidentId?: string;
    originalUsable: number;
    recommendedUsable: number;
    timestamp: number;
};
