import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UniversalCreditsSummary } from '../models/universal-credits-summary';

const UNIVERSAL_CREDITS_SUMMARY_ENDPOINT = '/rest/v1/universal-credits/summary';

@Injectable({ providedIn: 'root' })
export class UniversalCreditsSummaryService {
    constructor(protected http: HttpClient) {}

    get(): Observable<UniversalCreditsSummary> {
        return this.http.get(UNIVERSAL_CREDITS_SUMMARY_ENDPOINT).pipe(map(res => new UniversalCreditsSummary(res)));
    }
}
