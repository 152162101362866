import { Component, Input } from '@angular/core';
import { SubscriptionAsset } from '@pure1/data';

@Component({
    selector: 'ever-modern-eligibility',
    templateUrl: './ever-modern-eligibility.component.html',
})
export class EverModernEligibilityComponent {
    @Input() readonly asset: SubscriptionAsset;

    isEmuBooked(asset: SubscriptionAsset): boolean {
        return asset.everModernUpgrade?.emuBookingStatus;
    }

    doesEmuHaveDate(asset: SubscriptionAsset): boolean {
        return asset.everModernUpgrade?.assetAgeTargetDate != null;
    }

    getEverModernTooltipHtml(asset: SubscriptionAsset): string {
        const everModern = asset.everModernUpgrade;
        let tooltipText = `<div class="header">
                                <span class="text-left">${asset.name}</span>
                            </div>
                            <hr>
                            <div class="sub-header">
                                <span>${everModern?.assetAgeTargetDate.format('YYYY-MM-DD')}</span>
                            </div>

                            <div class="emu-event-type">Ever Modern Upgrade</div>`;

        if (everModern?.newHwType) {
            tooltipText += `
                            <table>
                                <tr>
                                    <td>Controller Upgrade</td>
                                    <td>${everModern.newHwType.toUpperCase()}</td>
                                </tr>
                            </table>`;
        }

        if (everModern?.upgradeRecommendedAction) {
            tooltipText += `
                            <div>${everModern.upgradeRecommendedAction}</div>`;
        }
        return tooltipText;
    }
}
