import { Resource } from '../interfaces/resource';
import { Assignment } from './assignment';

export class ExternalUser extends Assignment implements Resource {
    id: string;
    name: string;
    email: string;

    constructor(user: any) {
        super(user);
        this.id = user.name;
        this.name = user.name;
        this.email = user.name;
    }
}
