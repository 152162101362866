<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<ng-template #dpaLinksTemplate>
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/003_Dashboards_and_Appliances/01_Pure1_Manage_-_Assessment"
        target="_blank"
        class="bold-link"
    >
        See the documentation
    </a>
    |
    <a
        href="https://blog.purestorage.com/purely-technical/pure1-data-protection-assessment-tool"
        target="_blank"
        class="bold-link"
    >
        Read the blog
    </a>
    |
    <a href="https://youtu.be/w0q4d6Tv4Tc" target="_blank" class="bold-link"> Watch a video </a>
</ng-template>

<ng-template #dpaButtonTemplate>
    <a *ngIf="notification.path" [routerLink]="notification.path" class="btn btn-primary try-button">
        Protect your data
    </a>
</ng-template>
