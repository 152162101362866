import { Resource } from '../interfaces/resource';
import { AdditionalRoleAssignment } from './additional-role';
import { Assignment } from './assignment';

export class User extends Assignment implements Resource {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    timezone: string;
    partner: boolean;
    name: string;
    roleAlias: string;
    /** @deprecated since its type ({@link RoleType}) is deprecated */
    role: string;
    additionalRoles: AdditionalRoleAssignment[];

    constructor(user: any) {
        super(user);
        this.id = user.id;
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.timezone = user.timezone;
        this.partner = user.partner;
        this.name = user.name;
        this.role = user.role;
        this.roleAlias = user.roleAlias;
        this.additionalRoles = (user.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
    }
}
