<form [formGroup]="confirmForm">
    <div class="row">
        <div class="col-xs-12 mb-1">
            Please review your request. After clicking "Submit" our Support team will contact you to set up a meeting to
            start this process.
        </div>
    </div>
    <div class="row">
        <div class="col-xs-7">
            <div class="header left-header mb-1">
                <div class="inline-column column-1 column-extra-padding">Appliances</div>
                <div class="inline-column column-2">Date</div>
                <div class="inline-column column-3">Time</div>
            </div>
            <div class="selected-appliances-wrapper">
                <ul class="selected-appliances-list">
                    <li
                        *ngFor="let scheduledArray of schedule?.arrays; let i = index; trackBy: trackByArrayId"
                        class="selected-appliances-list-item"
                    >
                        <div class="column-1">
                            <span class="dot" [ngClass]="'dot-' + (i + 1)"></span>
                            <span class="name">{{ scheduledArray.array.name }}</span
                            ><br />
                            <span class="version">{{ scheduledArray.array.version }}</span>
                        </div>
                        <ng-container>
                            <div
                                class="column-2 top-aligned"
                                [ngClass]="{ unavailable: scheduledArray.timeslot === null }"
                                >{{
                                    scheduledArray.timeslot
                                        ? getDateFromMoment(scheduledArray.timeslot.startTime)
                                        : 'Unavailable!'
                                }}</div
                            >
                            <div
                                class="column-3 top-aligned"
                                [ngClass]="{ unavailable: scheduledArray.timeslot === null }"
                                >{{
                                    scheduledArray.timeslot
                                        ? getTimeAndDurationFromMoments(
                                              scheduledArray.timeslot.startTime,
                                              scheduledArray.timeslot.duration
                                          )
                                        : 'Unavailable!'
                                }}</div
                            >
                        </ng-container>
                    </li>
                </ul>
                <div class="float-xs-right mt-1">
                    <div>Time zone: {{ getTimezonePrettyName(schedule?.timezone) }}</div>
                </div>
                <div
                    class="timeslot-checking-spinner"
                    [ngClass]="{ 'timeslot-checking-spinner-visible': timeslotsChecking }"
                >
                    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                    <span class="pl-1">Checking timeslots...</span>
                </div>
            </div>
        </div>
        <div class="col-xs-5">
            <div class="row">
                <div class="col-xs-12 mb-1 header">Comments</div>
            </div>
            <div class="row">
                <div class="col-xs-12 mb-1">
                    <textarea class="form-control comment-text-area" type="text" formControlName="comment"> </textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 mb-3">
                    <label class="form-check-label" for="best-practices">
                        <input
                            id="best-practices"
                            class="form-check-input"
                            type="checkbox"
                            formControlName="bestPracticesCheck"
                        />
                        I have read the
                        <a
                            href="https://support.purestorage.com/FlashArray/FlashArray_Security/SafeMode/How_to_Enable_SafeMode_and_SafeMode_Best_Practices"
                            target="_blank"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Assessment - SafeMode - Confirmation - click safemode best practices"
                        >
                            SafeMode best practices
                        </a>
                        <span class="info-icon"
                            ><pure-info-icon
                                tooltipContent="Please review the checklist in our best practice guide to learn of your todo’s before the process starts"
                            ></pure-info-icon
                        ></span>
                    </label>
                </div>
                <div class="row">
                    <div class="col-xs-12" *ngIf="timeslotsConflictFlag">
                        <div class="alert alert-warning" role="alert">
                            Unfortunately, some of the requested time slots are no longer available. Please select the
                            “Back” button to re-select a requested time for those appliances.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="row">
    <div class="col-xs-12">
        <div class="btn-toolbar clearfix">
            <button
                type="button"
                id="continue-button"
                class="btn btn-primary float-xs-right margin"
                (click)="continue()"
                [disabled]="
                    ('PURE1:write:support_cases' | isNotAllowedImpure) || !confirmForm.valid || timeslotsChecking
                "
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Assessment - SafeMode - Confirmation - Submit schedule request"
            >
                Submit
            </button>
            <button
                type="button"
                class="btn btn-primary float-xs-right margin btn-custom"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Assessment - SafeMode - Confirmation - Go back during schedule request"
                (click)="onBack.emit()"
            >
                &lt; Back
            </button>
            <button
                type="button"
                class="btn btn-info float-xs-right margin btn-custom"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Assessment - SafeMode - Confirmation - Cancel schedule request"
                (click)="onCancel.emit()"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
