import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { UniversalCreditsCreditPool } from '../models/universal-credits-credit-pool';

const UNIVERSAL_CREDITS_POOL_ENDPOINT = '/rest/v1/universal-credits/pools';

@Injectable({ providedIn: 'root' })
export class UniversalCreditsCreditPoolService extends GenericService<UniversalCreditsCreditPool> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: UniversalCreditsCreditPool,
            endpoint: UNIVERSAL_CREDITS_POOL_ENDPOINT,
        });
    }
}
