import { Component, Input, OnChanges } from '@angular/core';
import { Theme } from '../../ui/styles/theme';

@Component({
    selector: 'pureui-protection-gauge',
    templateUrl: 'protection-gauge.html',
})
export class ProtectionGaugeComponent implements OnChanges {
    @Input() readonly size: number;
    @Input() readonly total: number;
    @Input() readonly count: number;
    @Input() readonly entity: 'volumes' | 'file systems';

    percentage = 0;
    gaugeColor: string;

    ngOnChanges(): void {
        if (this.total > 0) {
            this.percentage = Math.round(100 * (this.count / this.total));
        } else {
            this.percentage = 0;
        }
        this.gaugeColor = this.entity === 'volumes' ? Theme.capacity.volumes.fill : Theme.capacity.fileSystems.fill;
    }
}
