import { Component, EventEmitter, Output } from '@angular/core';

import { BaseBanner } from '../base-banner';

@Component({
    selector: 'feature-banner',
    templateUrl: 'feature-banner.component.html',
})
export class FeatureBannerComponent extends BaseBanner {
    // clean up once this banner expires
    readonly pure360BannerId = 'A000115';
    @Output() readonly dismissNotification = new EventEmitter<void>();
}
