import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnDestroy,
    Output,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { License, LicenseType, ProgramType, Subscription } from '@pure1/data';
import { LicenseTypeMappingService } from '../../services/license-type-mapping.service';
import {
    isServiceTierSupported,
    LicenseTypeDetail,
    getLicenseTypeDetail,
    migrateToServiceTier,
    convertToNgbDateStruct,
    convertToDate,
} from '../../subscription-utils/subscription-utils';

@Component({
    selector: 'update-license',
    templateUrl: './update-license.component.html',
})
export class UpdateLicenseComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
    @Input() readonly license: License;
    @Input() readonly subscription: Subscription;
    @Input() readonly programType: ProgramType;
    @Input() readonly increaseAmount: number;
    @Input() readonly isQuoteRenewal: boolean = false;
    @Output() readonly increaseAmountChange = new EventEmitter<number>();

    @ViewChild('existingLicensesExpansionsDatePicker') existingLicensesExpansionsDatePicker: NgbInputDatepicker;

    licenseTypeDetail: LicenseTypeDetail;
    licenseTypes: LicenseTypeDetail[];
    existingLicenseChangeDateModel: NgbDateStruct;
    infoMessage: string = null;

    private readonly destroy$ = new Subject<void>();

    constructor(private licenseTypeMappingService: LicenseTypeMappingService) {}

    ngAfterViewInit(): void {
        if (!this.isQuoteRenewal) {
            const todayPlusAWeek = moment().add(7, 'day');
            const oneYearFromEndDate = this.subscription.endDate.add(1, 'year');
            this.existingLicensesExpansionsDatePicker.firstDayOfWeek = 7;
            this.existingLicensesExpansionsDatePicker.minDate = convertToNgbDateStruct(todayPlusAWeek);
            this.existingLicensesExpansionsDatePicker.maxDate = todayPlusAWeek.isAfter(this.subscription.endDate)
                ? convertToNgbDateStruct(oneYearFromEndDate)
                : convertToNgbDateStruct(this.subscription.endDate);
            // also disabling weekends. 6 = Saturday, 7 = Sunday
            this.existingLicensesExpansionsDatePicker.markDisabled = (date: NgbDateStruct) => {
                const dayOfWeek = moment(convertToDate(date)).isoWeekday();
                return dayOfWeek === 6 || dayOfWeek === 7;
            };
        }
    }

    ngOnInit(): void {
        this.licenseTypeMappingService
            .getTypeMapping()
            .pipe(takeUntil(this.destroy$))
            .subscribe(typeMap => {
                this.licenseTypes = typeMap
                    .get(this.programType)
                    .map(licenseType => getLicenseTypeDetail(licenseType))
                    .filter(f => f != null);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.license) {
            this.licenseTypeDetail = getLicenseTypeDetail(this.license.licenseType);

            if (this.license?.startDate) {
                this.existingLicenseChangeDateModel = convertToNgbDateStruct(this.license.startDate);
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    amountChanged(amount: number): void {
        this.increaseAmountChange.emit(amount);
    }

    onLicenseStartDateChange(date: NgbDateStruct): void {
        this.license.startDate = moment(convertToDate(date));
        this.existingLicenseChangeDateModel = date;
    }

    onLicenseTypeChange(selectedLicenseType: LicenseType): void {
        if (this.license.licenseType !== selectedLicenseType) {
            this.infoMessage =
                'You have selected a different service level from your current license. A new subscription will be created for this.';
        } else {
            this.infoMessage = null;
        }
    }

    isServiceTierSupported(): boolean {
        return isServiceTierSupported(this.programType);
    }

    migrateToServiceTier(licenseType: LicenseType): LicenseType {
        return migrateToServiceTier(licenseType);
    }
}
