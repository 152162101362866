<div>
    <!-- FA/FB -->
    <div class="summary summary-array">
        <div class="data-reduction-container">
            <div class="capacity-dial-outer">
                <capacity-dial [size]="127" [arcs]="array.capacityData.dialSegments"></capacity-dial>
                <div class="card-capacity-perc">{{ array.capacityData.normalizedPercFull }}</div>
                <div
                    class="card-capacity-down-licensing-icon-container"
                    *ngIf="capacityDownLicensingEnabled && array.capacityData.capacityExpandable"
                >
                    <capacity-down-licensing-icon [applianceId]="array.arrayId"></capacity-down-licensing-icon>
                </div>
            </div>
            <div class="reduction-outer">
                <div class="reduction-value">{{ array.capacityData.dataReductionStr }}</div>
                <div>Data Reduction</div>
            </div>
            <div *ngIf="array.safeModeStatus !== safeModeStatusEnum.NOT_AVAILABLE" class="card-safemode-icon-container">
                <hive-menu position="left" #menu="hiveMenu">
                    <hive-menu-item
                        label="View Assessment"
                        [routerLink]="getSafeModeState()"
                        [queryParams]="getSafeModeUIParams(array)"
                    >
                    </hive-menu-item>
                    <hive-menu-item label="Change SafeMode" (click)="openSafeModeMultipartAuthModal(array)">
                    </hive-menu-item>
                </hive-menu>
                <safemode-shield-icon
                    *ngIf="
                        safeModeMultipartyFeatureFlag && array.safeModeStatus !== safeModeStatusEnum.UPGRADE_REQUIRED
                    "
                    hiveMenuTrigger
                    [menuRef]="menu"
                    [arraySafeModeStatus]="array.safeModeStatus"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Arrays - click safemode icon"
                    [angularticsLabel]="array.safeModeStatus"
                >
                </safemode-shield-icon>
                <a
                    [routerLink]="getSafeModeState()"
                    [queryParams]="getSafeModeUIParams(array)"
                    validateHref="PURE1:read:safemode"
                    *ngIf="
                        !safeModeMultipartyFeatureFlag || array.safeModeStatus === safeModeStatusEnum.UPGRADE_REQUIRED
                    "
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Arrays - click safemode icon"
                    [angularticsLabel]="array.safeModeStatus"
                >
                    <safemode-shield-icon [arraySafeModeStatus]="array.safeModeStatus"></safemode-shield-icon>
                </a>
            </div>
        </div>
        <div class="capacity-legend">
            <table>
                <tbody>
                    <tr *ngFor="let entry of capacityMetadata; trackBy: 'rawKey' | trackByProperty">
                        <td>
                            <div
                                class="legend-rect"
                                [ngStyle]="{ 'background-color': entry.fill, border: entry.outline }"
                            ></div>
                        </td>
                        <td>
                            <div class="legend-label" [ngbTooltip]="entry.tooltipText || ''" container="body">
                                {{ entry.label }}
                            </div>
                        </td>
                        <td
                            ><div class="legend-value">{{ array.capacityData[entry.key].value }}</div></td
                        >
                        <td
                            ><div class="legend-unit">{{ array.capacityData[entry.key].unit }}</div></td
                        >
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="usage-outer">
            <div class="usage-inner">
                <span class="usage-value">{{ array.capacityData.capacityUsedStr.value }}</span>
                <span class="usage-unit">{{ array.capacityData.capacityUsedStr.unit }}</span>
                &nbsp;<span class="usage-unit">/</span>&nbsp;
                <span class="usage-value">{{ array.capacityData.capacityStr.value }}</span>
                <span class="usage-unit">{{ array.capacityData.capacityStr.unit }}</span>
            </div>
        </div>
    </div>

    <!-- Chart (expanded) -->
    <div *ngIf="isExpanded">
        <array-expanded-card-capacity-chart [array]="array" [capacityMetadata]="$any(capacityMetadata)">
        </array-expanded-card-capacity-chart>
    </div>
</div>
