<svg
    class="capacity-expandable-icon"
    [width]="20"
    [height]="20"
    hive-svg="capacity_down_icon_active.svg"
    hiveTooltip
    hiveTooltipDescription="Additional installed capacity available. Click here to learn more."
    [ngbPopover]="capacityDownLicensingPopover"
    popoverClass="capacity-down-licensing-popover"
    placement="bottom"
    container="body"
></svg>
<ng-template #capacityDownLicensingPopover>
    <div class="capacity-down-licensing-popover-wrapper">
        <div class="image-column">
            <img src="/images/capacity-image.svg" alt="capacity-image" />
        </div>
        <div class="text-column">
            <h3 class="font-mildly-bold">Optimize Your Usable Capacity!</h3>
            <div class="body">
                This appliance includes additional installed capacity, enabling faster and more efficient capacity
                expansion when needed.
            </div>
            <div class="buttons-section">
                <div class="row">
                    <div class="col-xs-6">
                        <hive-button
                            label="Plan Capacity Expansion"
                            emphasis="primary"
                            class="yes-btn"
                            (click)="upgradeClickHandler()"
                        ></hive-button>
                    </div>
                    <div class="col-xs-6">
                        <hive-button label="Maybe later" emphasis="secondary" class="no-btn"></hive-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
