import { Moment } from 'moment';
import { Resource } from '../interfaces/resource';

export enum UniversalCreditsStatus {
    ACTIVE = 'Active',
    EXPIRING_SOON = 'Expiring Soon',
    EXPIRED = 'Expired',
}

export class UniversalCreditsCreditPool implements Resource {
    readonly id: string;
    readonly name: string;
    readonly salesforceId: string;
    readonly startDate: Moment;
    readonly endDate: Moment;
    readonly status: UniversalCreditsStatus;
    readonly usablePercent: number;
    readonly usableAmount: number;
    readonly originalAmount: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.salesforceId = json.salesforce_id;
        this.startDate = json.start_date;
        this.endDate = json.end_date;
        this.status = json.status;
        this.usablePercent = json.usable_percent;
        this.usableAmount = json.usable_amount;
        this.originalAmount = json.original_amount;
    }
}
