<pure-carousel *ngIf="fullNotifications.length > 0" [slideTemplates]="slides" [slideNotifications]="fullNotifications">
</pure-carousel>

<!--
    The order these banners are structured in the final dom will be the order they are displayed in the carousel.
    The warning banner gets priority in the carousel, while all else will appear in the order of the `notifications` array.
-->

<ng-template *ngIf="securityPatchFb20250107Notification" #bannerSlides>
    <warning-banner [warningNotification]="securityPatchFb20250107Notification"> </warning-banner>
</ng-template>

<ng-template *ngIf="securityPatchFb20240115Notification" #bannerSlides>
    <warning-banner [warningNotification]="securityPatchFb20240115Notification"> </warning-banner>
</ng-template>

<ng-template *ngIf="warningBannerNotification" #bannerSlides>
    <warning-banner [warningNotification]="warningBannerNotification"> </warning-banner>
</ng-template>

<ng-template *ngIf="safemodeOrgNonCompliantBannerNotification" #bannerSlides>
    <warning-banner [warningNotification]="safemodeOrgNonCompliantBannerNotification"> </warning-banner>
</ng-template>

<ng-template *ngIf="atomSecurityPatchNotification" #bannerSlides>
    <warning-banner [warningNotification]="atomSecurityPatchNotification"> </warning-banner>
</ng-template>

<ng-template *ngFor="let notification of notifications" #bannerSlides>
    <ng-container [ngSwitch]="notification.id">
        <user-research-banner
            *ngSwitchCase="userResearchBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </user-research-banner>
        <optimize-capacity-banner
            *ngSwitchCase="optimizeCapacityBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        ></optimize-capacity-banner>
        <nps-survey-banner
            *ngSwitchCase="npsSurveyBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNps(notification, $event)"
        >
        </nps-survey-banner>
        <safemode-banner
            *ngSwitchCase="safeModeBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        ></safemode-banner>
        <cdu-banner
            *ngSwitchCase="cduBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </cdu-banner>
        <auto-on-safe-mode-banner
            *ngSwitchCase="autoOnSMBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </auto-on-safe-mode-banner>
        <dpa-banner
            *ngSwitchCase="dpaBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </dpa-banner>
        <eol-or-eosc-warning-carousel-banner
            *ngSwitchCase="hwEolBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </eol-or-eosc-warning-carousel-banner>
        <eol-or-eosc-warning-carousel-banner
            *ngSwitchCase="eoscBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </eol-or-eosc-warning-carousel-banner>
        <eol-or-eosc-warning-carousel-banner
            *ngSwitchCase="eoscAndEolBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </eol-or-eosc-warning-carousel-banner>
        <drr-banner
            *ngSwitchCase="drrBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </drr-banner>
        <x-to-xl-upgrade-banner
            *ngSwitchCase="xToXlUpgradeBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </x-to-xl-upgrade-banner>
        <sdfc-update-banner
            *ngSwitchCase="sdfcUpdateBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </sdfc-update-banner>
        <enhanced-safemode-banner
            *ngSwitchCase="enhancedSafemodeBannerId"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </enhanced-safemode-banner>
        <!-- NgSwitch doesn't support multi values yay! -->
        <opportunity-creation-banner
            *ngSwitchCase="fbEolBannerId"
            [config]="opportunityBannerConfigs.get(fbEolBannerId)"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </opportunity-creation-banner>
        <opportunity-creation-banner
            *ngSwitchCase="faCNonFanInBannerId"
            [config]="opportunityBannerConfigs.get(faCNonFanInBannerId)"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </opportunity-creation-banner>
        <opportunity-creation-banner
            *ngSwitchCase="faCFanInBannerId"
            [config]="opportunityBannerConfigs.get(faCFanInBannerId)"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </opportunity-creation-banner>
        <opportunity-creation-banner
            *ngSwitchCase="pure1RepatriationBannerId"
            [config]="opportunityBannerConfigs.get(pure1RepatriationBannerId)"
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </opportunity-creation-banner>
        <feature-banner
            *ngSwitchDefault
            [notification]="notification"
            (dismissNotification)="onDismissNotification(notification)"
        >
        </feature-banner>
    </ng-container>
</ng-template>
