<div class="container-fluid array-info">
    <div class="row">
        <div class="col-xs-6">{{ selectedArray?.model }}</div>
        <div class="col-xs-6">{{ selectedArray?.os }} {{ selectedArray?.version }}</div>
    </div>
</div>
<div
    class="array-support-expired"
    *ngIf="{
        viewSupportRenewal: 'PURE1:view:support_renewal' | isAllowedImpure
    } as authorization"
>
    <pureui-svg svg="support_icon.svg" [height]="supportIconSize"></pureui-svg>
    <div class="expiration-text descriptive-text"
        >Support for this appliance expired on
        <span class="expiration-date">{{ $any(selectedArray?.contract_expiration) | date: 'yyyy-MM-dd' }}</span></div
    >
    <ng-container *ngIf="authorization.viewSupportRenewal && !shouldShowContactSalesMessage(); else showNonRequestView">
        <div
            class="renew-button-container"
            *ngIf="
                selectedArray?.contract_renewal_status === 'Renewable and Unacknowledged' &&
                selectedArrayExistingQuote?.editable
            "
        >
            <button
                class="btn btn-primary expired-appliance-renew-button"
                [ngClass]="{ disabled: loading }"
                (click)="requestSupportContractRenewal()"
                angulartics2On="click"
                [angularticsAction]="analyticsAction + 'request-renewal'"
                >Request Renewal
            </button>
        </div>
        <div class="renewed-container" *ngIf="selectedArray?.contract_renewal_status === 'Renewable and Acknowledged'">
            <pureui-svg class="check-mark-icon" svg="circle_checkmark.svg" height="20"></pureui-svg>
            <span class="descriptive-text">Renewal Requested</span>
        </div>
    </ng-container>
    <ng-template #showNonRequestView>
        <div class="no-action-container" *ngIf="!authorization.viewSupportRenewal">
            <span class="descriptive-text normal-white-space-wrap"
                >Please contact your Pure1 administrator to renew support for this appliance.</span
            >
        </div>
        <div class="no-action-container" *ngIf="authorization.viewSupportRenewal && shouldShowContactSalesMessage()">
            <span class="contact-text">
                Please contact
                <a
                    href="https://support.purestorage.com/Pure_Storage_Technical_Services/Technical_Services_Information/Contact_Us"
                    target="_blank"
                >
                    Pure Technical Services</a
                >
                or your account team to renew your Evergreen Subscription for this array.
            </span>
        </div>
    </ng-template>
</div>
<div class="p-loading">
    <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
</div>
