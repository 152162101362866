import { Resource } from '../interfaces/resource';
import { AdditionalRoleAssignment } from './additional-role';

export enum AssigneeType {
    USER = 'USER',
    GROUP = 'GROUP',
    EXTERNAL = 'EXTERNAL',
    SSO_USER = 'SSO_USER',
    PUBLIC_API = 'PUBLIC_API',
}

export class RoleAssignment implements Resource {
    assigneeType: AssigneeType;
    id: string;
    name: string;
    role: string;
    additionalRoles: AdditionalRoleAssignment[] = [];
    viewId: string;
    viewName: string;
    roleAlias: string;

    constructor(json: any) {
        this.additionalRoles = (json.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
        this.assigneeType = json.assignee_type;
        this.id = json.entity_id;
        this.name = json.name;
        this.role = json.role;
        this.viewId = json.view_id;
        this.viewName = json.view_name;
        this.roleAlias = json.role_alias;
    }
}
