/**
 * Smart timer might be a good thing to use, but it providers no mechanism to override it in tests.
 * It could be even one of the reasons why appliance-view.component had only two specs as of 2025-01-15.
 * So, to simplify testing smartTimer became injectable.
 */
import { InjectionToken } from '@angular/core';
import { smartTimer as originalSmartTimer } from '@pstg/smart-timer';

export type SmartTimer = typeof originalSmartTimer;

export const SMART_TIMER = new InjectionToken<SmartTimer>('SMART_TIMER', {
    factory: () => originalSmartTimer,
});
