import moment from 'moment';

import { Resource } from '../interfaces/resource';
import { ActivationPeriod, Address, LicenseType, LicenseUsage } from './license';
import { QuoteStatus } from './opportunity-quote';
import { ProgramType, SubscriptionStatus } from './subscription';
import { Tag } from './tag';
import { ArraySafeModeStatus, toSafeModeStatus } from './array-safemode';

export class LicenseInfo {
    id: string;
    name: string;
    siteAddress: Address;
    activationDate: moment.Moment;
    endDate: moment.Moment;
    lastUpdated: moment.Moment;
    licenseType: LicenseType;
    reservedAmount: number;
    reservedUnit: string;
    usage: LicenseUsage;
    preRatio: number;
    powerUsage: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.siteAddress = new Address(json.site_address || {});
        this.activationDate = json.activation_date ? moment.utc(json.activation_date) : null;
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
        this.lastUpdated = json.last_updated ? moment.utc(json.last_updated) : null;
        this.licenseType = json.license_type;
        this.reservedAmount = json.reserved_amount;
        this.reservedUnit = json.reserved_unit;
        this.usage = new LicenseUsage(json.usage || {});
        this.preRatio = json.pre_ratio;
        this.powerUsage = json.power_usage;
    }
}

export class SubscriptionInfo {
    id: string;
    name: string;
    initialName: string;
    orgName: string;
    partnerAccountName: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    lastUpdated: moment.Moment;
    programType: ProgramType;
    term: number;
    status: SubscriptionStatus;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.initialName = json.initial_subscription_name;
        this.orgName = json.org_name;
        this.partnerAccountName = json.partner_account_name;
        this.startDate = json.start_date ? moment.utc(json.start_date) : null;
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
        this.lastUpdated = json.last_updated ? moment.utc(json.last_updated) : null;
        this.programType = json.program_type;
        this.term = json.subscription_term;
        this.status = json.status;
    }
}

export class QuoteInfo {
    id: string;
    status: QuoteStatus;
    expirationDate: moment.Moment;

    constructor(json: any) {
        this.id = json.id;
        this.status = json.status;
        this.expirationDate = json.expiration_date ? moment.utc(json.expiration_date) : null;
    }
}

export class EolHardware {
    earliestEolDate: moment.Moment;
    eolComponents: EolComponent[];
    sortedComponentLabels: string[];
    constructor(json: any) {
        this.earliestEolDate = json.earliest_eol_date ? moment.utc(json.earliest_eol_date) : null;
        this.eolComponents = (json.eol_components || []).map(component => new EolComponent(component));
        this.sortedComponentLabels = json.sortedComponentLabels;
    }
}

export class EolComponent {
    eolDate: moment.Moment;
    flag: string;
    label: string;
    protocol: string;
    type: string;

    constructor(json: any) {
        this.eolDate = json.eol_date ? moment.utc(json.eol_date) : null;
        this.flag = json.flag;
        this.label = json.label;
        this.protocol = json.protocol;
        this.type = json.type;
    }
}

export class EverModernUpgrade {
    arrayId: string;
    // Is this asset old enough to receive an ever modern upgrade
    assetAgeCriterion: boolean;
    // When will this asset be old enough to receive an ever modern upgrade
    assetAgeTargetDate: moment.Moment;
    // Does this asset have Evergreen//Forever subscription renewed for long enough into the future
    foreverCommitmentCriterion: boolean;
    // How long into the future does Evergreen//Forever subscription needs to be renewed for this asset to be eligible
    nextEvergreenDate: moment.Moment;
    // Has customer booked this ever modern upgrade
    emuBookingStatus: boolean;
    // When is this ever modern upgrade expected to ship
    emuExpectedShipDate: moment.Moment;
    // When did this ever modern upgrade ship
    emuConfirmedShipDate: moment.Moment;
    // Is professional services required for this ever modern upgrade
    psWithEmu: boolean;
    // Last day that the customer is still eligible for this ever modern upgrade
    emuLastBookingDate: moment.Moment;
    // New controller model to upgrade to
    newHwType: string;
    // Recommended action
    upgradeRecommendedAction: string;

    constructor(json: any) {
        this.arrayId = json.array_id;
        this.assetAgeCriterion = json.asset_age_criterion;
        this.assetAgeTargetDate = json.asset_age_target_date ? moment.utc(json.asset_age_target_date) : null;
        this.foreverCommitmentCriterion = json.forever_commitment_criterion;
        this.nextEvergreenDate = json.next_evergreen_date ? moment.utc(json.next_evergreen_date) : null;
        this.emuBookingStatus = json.emu_booking_status;
        this.emuExpectedShipDate = json.emu_expected_ship_date ? moment.utc(json.emu_expected_ship_date) : null;
        this.emuConfirmedShipDate = json.emu_confirmed_ship_date ? moment.utc(json.emu_confirmed_ship_date) : null;
        this.psWithEmu = json.ps_with_emu;
        this.emuLastBookingDate = json.emu_last_booking_date ? moment.utc(json.emu_last_booking_date) : null;
        this.newHwType = json.new_hw_type;
        this.upgradeRecommendedAction = json.upgrade_recommended_action;
    }
}

export class SubscriptionAsset implements Resource {
    id: string;
    name: string;
    activations: ActivationPeriod[];
    mostRecentActivationStartDate: moment.Moment;
    applianceId: string;
    arraySafeModeStatus: ArraySafeModeStatus;
    capacity: number;
    chassisSerial: string;
    cloudTags: Tag[]; // Only key and value need to be populated here
    currentUsage: number;
    dataReduction: number;
    energyUsage: number;
    installAddress: Address;
    isVisibleArray: boolean;
    lastUpdated: moment.Moment;
    model: string;
    percentFull: number;
    purityVersion: string;
    eolTimestamp: moment.Moment;
    eolHardware: EolHardware;
    everModernUpgrade: EverModernUpgrade;
    lastMetricRefresh: moment.Moment;

    license: LicenseInfo;
    subscription: SubscriptionInfo;

    quote: QuoteInfo;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.activations = (json.activations || []).map(activation => new ActivationPeriod(activation));
        if (json.most_recent_activation_start_date) {
            this.mostRecentActivationStartDate = moment.utc(json.most_recent_activation_start_date);
        } else {
            this.mostRecentActivationStartDate =
                this.activations.length > 0 ? this.activations[this.activations.length - 1].startDate : null;
        }
        this.applianceId = json.appliance_id;
        this.arraySafeModeStatus = toSafeModeStatus(json.safe_mode_array_status);
        this.capacity = json.capacity;
        this.chassisSerial = json.chassis_serial;
        this.cloudTags = (json.cloud_tags || []).map(tag => new Tag(tag));
        this.currentUsage = json.current_usage;
        this.dataReduction = json.data_reduction;
        this.energyUsage = json.last_7_days_power;
        this.installAddress = new Address(json.install_address || {});
        this.isVisibleArray = json.is_visible_array;
        this.lastUpdated = json.last_updated ? moment.utc(json.last_updated) : null;
        this.model = json.model;
        this.percentFull = json.percent_full;
        this.purityVersion = json.purity_version;
        this.eolTimestamp = json.eol_timestamp ? moment.utc(json.eol_timestamp) : null;
        this.eolHardware = json.eol_hardware ? new EolHardware(json.eol_hardware) : null;
        this.everModernUpgrade = json.ever_modern_upgrade ? new EverModernUpgrade(json.ever_modern_upgrade) : null;
        this.license = new LicenseInfo(json.license || {});
        this.subscription = new SubscriptionInfo(json.subscription || {});
        this.lastMetricRefresh = json.last_metric_refresh ? moment.utc(json.last_metric_refresh) : null;
        this.quote = new QuoteInfo(json.quote || {});
    }
}
