import { Resource } from '../interfaces/resource';

export class HardwareModels implements Resource {
    id: string;
    name: string;
    faXmodels: string[];
    faCmodels: string[];
    faEmodels: string[];
    faXLmodels: string[];

    private readonly familyToModelsMap: Map<string, string[]>;

    constructor(json: any) {
        this.id = 'hardware-models';
        this.name = 'hardware-models';
        this.faCmodels = json.fa_c;
        this.faXmodels = json.fa_x;
        this.faEmodels = json.fa_e;
        this.faXLmodels = json.fa_xl;

        this.familyToModelsMap = new Map<string, string[]>([
            ['//XL', this.faXLmodels],
            ['//X', this.faXmodels],
            ['//C', this.faCmodels],
            ['//E', this.faEmodels],
        ]);
    }

    getModels(productFamilyName: string): string[] {
        return this.familyToModelsMap.get(productFamilyName);
    }
}
