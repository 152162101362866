export class UniversalCreditsSummary {
    readonly availableCredits: number;
    readonly pendingCredits: number;
    readonly expiringSoon: number;

    constructor(json: any) {
        this.availableCredits = json.available_credits;
        this.pendingCredits = json.pending_credits;
        this.expiringSoon = json.expiring_soon;
    }
}
