import { Component, Output, EventEmitter } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { EOSC_AND_EOL_BANNER_ID, EOSC_BANNER_ID, HW_EOL_BANNER_ID } from '../banner-carousel/banner-carousel.component';
import { ampli } from '../../ampli';

/*
    This banner is mostly copied from FeatureBannerComponent, tweaked slightly and the extra bits stripped out.
    Doing that felt more appropriate than adding more special-cases to the FeatureBannerComponent directly.
*/
@Component({
    selector: 'eol-or-eosc-warning-carousel-banner',
    templateUrl: 'eol-or-eosc-warning-carousel-banner.component.html',
})
export class EolOrEoscWarningCarouselBannerComponent extends BaseBanner {
    @Output() readonly dismissNotification = new EventEmitter<void>();

    readonly ampli = ampli;

    getHwEolFilters(): string {
        if (this.notification.id === HW_EOL_BANNER_ID) {
            return JSON.stringify([{ entity: 'arrays', key: 'has_end_of_life_hardware', value: 'true' }]);
        } else if (this.notification.id === EOSC_BANNER_ID) {
            return JSON.stringify([{ entity: 'arrays', key: 'contract_status', value: 'Expired' }]);
        } else if (this.notification.id === EOSC_AND_EOL_BANNER_ID) {
            return JSON.stringify([
                { entity: 'arrays', key: 'has_end_of_life_hardware', value: 'true' },
                { entity: 'arrays', key: 'contract_status', value: 'Expired' },
            ]);
        }
    }
}
