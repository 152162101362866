import d3legacy from 'd3legacy';
import d3Tip from 'd3-tip';

import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    OnChanges,
    AfterViewInit,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { formatSize } from '../../../utils/formatSize';

(d3legacy as any).tip = d3Tip;

const tau = 2 * Math.PI;
const TOOLTIP_CLASS = 'capacity-dial-segment-tooltip';
const UNIQUE_SPACE_NAMES = ['Volumes', 'File Systems', 'Object Stores'];

let instanceId = 0;

interface ISegmentData {
    startAngle: number;
    endAngle: number;
    absoluteValue: number;
    tooltipName: string;
    cssClass: string;
    innerRadius?: number;
    outerRadius?: number;
}

@Component({
    selector: 'capacity-dial',
    templateUrl: 'capacity-dial.component.html',
})
export class CapacityDialComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() readonly size: number;
    @Input() readonly arcs: IDialSegment[];
    @Input() readonly enableTooltip: boolean;
    @Input() readonly centerLabel: string;
    @ViewChild('svgElem', { static: true }) readonly svgElem: ElementRef;

    private capacityDialContainer: d3.Selection<any>;
    private arc: d3.svg.Arc<any>;
    private arcsData: ISegmentData[];
    private pathElem: d3.Selection<any>;
    private isInitialized = false;
    private svgD3: d3.Selection<any>;
    private tooltipElem: any;
    private tooltipElemId: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isInitialized) {
            this.drawArcs();
        }
    }

    ngAfterViewInit(): void {
        this.tooltipElemId = 'capacity-dial-tooltip-' + instanceId++;

        this.svgD3 = d3legacy
            .select(this.svgElem.nativeElement)
            .attr('width', this.size || 0)
            .attr('height', this.size || 0);

        this.capacityDialContainer = this.svgD3.append('g');

        // Tooltip
        if (this.enableTooltip) {
            this.tooltipElem = (d3legacy as any)
                .tip()
                .attr('class', TOOLTIP_CLASS)
                .attr('id', this.tooltipElemId)
                .html((data: any) => this.getTooltipHtml(data));

            this.svgD3.call(this.tooltipElem);
        }
        const dialSVG = d3legacy.select(this.svgElem.nativeElement).attr('width', this.size).attr('height', this.size);

        if (this.centerLabel) {
            this.drawLabel(dialSVG);
        }

        this.capacityDialContainer = dialSVG
            .append('g')
            .attr('transform', `translate(${this.size / 2}, ${this.size / 2})`);

        // Draw background arc
        this.arc = d3legacy.svg.arc();

        this.pathElem = this.capacityDialContainer
            .append('path')
            .datum({
                startAngle: 0,
                endAngle: tau,
                innerRadius: this.size / 2 - this.size * 0.12,
                outerRadius: this.size / 2,
                value: 0,
            })
            .attr('class', 'background-arc');

        this.isInitialized = true;
        this.drawArcs();
    }

    ngOnDestroy(): void {
        if (this.tooltipElem) {
            this.tooltipElem.hide();
            d3legacy.selectAll('#' + this.tooltipElemId).remove();
        }
    }

    private drawLabel(dialSVG: any): void {
        const text = dialSVG.append('text');

        text.attr('text-anchor', 'middle')
            .attr('y', '50%')
            .attr('x', '50%')
            .attr('dy', '0.35em')
            .attr('font-size', '12px')
            .attr('fill', '#717171')
            .text(this.centerLabel);
    }

    private getTooltipHtml(selectedData: ISegmentData): string {
        let uniqueDetailHtml = '';
        let rowsHtml = '';
        this.arcsData.forEach(data => {
            const formatted = formatSize(data.absoluteValue, 2);
            const isSelected = data.tooltipName === selectedData.tooltipName;
            const generatedTableRow = `
            <tr class="${isSelected ? 'selected' : ''}">
                <td>
                    <span class="entityColor ${data.cssClass}"></span>
                    <span class="metricName metricNameExtraWide">${data.tooltipName}</span>
                    <span class="metricValue metricValueRightAlign pull-right">${formatted.value} ${formatted.unit}</span>
                </td>
            </tr>`;
            if (UNIQUE_SPACE_NAMES.includes(data.tooltipName)) {
                uniqueDetailHtml += generatedTableRow;
            } else {
                rowsHtml += generatedTableRow;
            }
        });

        return `
            <div class="perf-chart-tooltip space-widget-tooltip-container">
                <div class="tt-header">
                    <div class="header-left">Capacity</div>
                </div>
                <div class="tt-body">
                    <table class="table">
                        ${rowsHtml}
                    </table>
                </div>
            </div>`;
    }

    /**
     * Draws the arcs
     */
    private drawArcs(): void {
        const countNonZeroValues = (count: number, arc: IDialSegment) => {
            return count + (arc.value > 0 ? 1 : 0);
        };

        // Remove previously drawn arcs
        this.capacityDialContainer.selectAll('path.arcs').remove();

        if (!this.capacityDialContainer || !this.arcs || !(this.size > 0)) {
            return;
        }

        // filter out any data with NaN as value (will cause draw errors if present)
        // also normalize the arcs of 'volumes, file systems, and object stores' against unique if present
        const validArcs = this.arcs.filter(arc => !Number.isNaN(arc.value));

        // Update size
        this.svgD3.attr('width', this.size).attr('height', this.size);

        this.capacityDialContainer.attr('transform', `translate(${this.size / 2}, ${this.size / 2})`);

        this.arc.innerRadius(this.size / 2 - this.size * 0.12).outerRadius(this.size / 2);

        this.pathElem.attr('d', this.arc);

        // Draw new arcs
        let startAngle = 0;

        const arcs = this.capacityDialContainer.selectAll('path.arcs').data(validArcs);

        this.arcsData = [];

        // Do not bother to show the stroke if we have only 1 nonzero value
        const strokeWidth = validArcs.length > 1 && validArcs.reduce(countNonZeroValues, 0) > 1 ? 1 : 0;

        const tooltipElem = this.tooltipElem;
        const enableTooltip = this.enableTooltip;
        const halfSize = this.size / 2;
        arcs.enter().append('path').attr('class', 'arcs');
        arcs.each(function (d: IDialSegment): void {
            // Cannot use => here. Since we want 'this' to refer to be the current DOM element provided by d3js
            const elem = d3legacy.select(this);
            elem.classed(d.cssClass, true);

            if (enableTooltip) {
                elem.on('mouseover', tooltipElem.show).on('mouseout', tooltipElem.hide);
            }
        })
            .datum((d: IDialSegment) => {
                const valueToUse = d.normalizedValue || d.value; // use normalized value is present so space breakdown arcs fit within unique arc
                const endAngle = (valueToUse / 100) * tau + startAngle;
                const data: ISegmentData = {
                    startAngle: startAngle,
                    endAngle: endAngle,
                    absoluteValue: d.absoluteValue,
                    tooltipName: d.tooltipName,
                    cssClass: d.cssClass,
                    innerRadius: halfSize - this.size * 0.12,
                    outerRadius: halfSize,
                };
                startAngle = endAngle;

                this.arcsData.push(data);
                return data;
            })
            .style('stroke', 'white')
            .style('stroke-width', strokeWidth)
            .attr('d', this.arc);

        arcs.exit().remove();
    }
}
