import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    Resource,
    PhantomArray,
    PhantomArraysService,
    getProduct,
    Product,
    isPhantomArray,
    FeatureFlagDxpService,
} from '@pure1/data';

import { switchTap } from '../../utils/rxjs-operators';
import { SimulationsService, stripResource } from '../simulation-summary/simulations.service';
import { ForecastedArray } from './forecasted-arrays-manager.service';
import { isFA3xx, isVMware, isFAE, isCBS, isPhantomFB, isFlashblade } from '../../utils/marketing';
import { SimulationStep } from '../../services/simulation-step.service';
import { supportsCloneParams } from './forecast-ui.service';
import { FeatureNames } from '../../model/FeatureNames';

export type PhantomArrayProductType = 'FA' | 'FB';

/**
 * Helper methods for working with phantom arrays
 */

interface CreatePhantomArrayRequest {
    readonly id: string;
    readonly name: string;
    readonly workload_placement_steps: Partial<SimulationStep>[];
    readonly source_array: Resource;
}

@Injectable({ providedIn: 'root' })
export class PhantomArrayUtilsService {
    private isFAEWorkloadAndCloneSimulationsSupported = false;

    constructor(
        private phantomArraysService: PhantomArraysService,
        private simulationsService: SimulationsService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.FA_E_WORKLOAD_AND_CLONE_SIMULATIONS)
            .pipe(take(1))
            .subscribe(feature => {
                this.isFAEWorkloadAndCloneSimulationsSupported = feature?.enabled === true;
            });
    }

    /**
     * Creates a new phantom array with a unique name.
     * @param productType type of the phantomArray, only FA and FB supported now.
     * @param existingArrays List of existing arrays (used to generate unique name)
     * @param newSimulationSteps List of workload simulation steps, used while create phantom arrays in the workload simulation modal.
     */
    createPhantomArray(
        productType: PhantomArrayProductType,
        existingArrays: Resource[],
        newSimulationSteps?: Partial<SimulationStep>[],
    ): Observable<PhantomArray> {
        return this.simulationsService.simulation$.pipe(
            take(1),
            switchMap(simulation => {
                // Create the new phantom array
                let baseName: string;
                let id: string;
                if (!productType) {
                    // productType == null, for the phantom:new_array
                    baseName = 'New-Array';
                    id = 'phantom:new_array';
                } else {
                    baseName = productType === 'FA' ? 'New-FlashArray' : 'New-FlashBlade';
                    id = productType === 'FA' ? 'phantom:fa:new_array' : 'phantom:fb:new_array';
                }
                const name = this.generatePhantomArrayName(existingArrays, baseName);
                return this.phantomArraysService.create(
                    <CreatePhantomArrayRequest>{ id: id, name: name, workload_placement_steps: newSimulationSteps },
                    { simulation_id: simulation.id },
                );
            }),
            switchTap(_ => {
                // Force update of simulation steps to fetch the steps from creating a new phantom array
                return this.simulationsService.refreshSimulationSteps();
            }),
        );
    }

    /**
     * Creates a new phantom array with a unique name, cloning the hardware and workload of an existing array.
     * @param existingArrays List of existing arrays (used to generate unique name)
     * @param sourceArray the array to clone from
     */
    clonePhantomArray(existingArrays: Resource[], sourceArray: ForecastedArray): Observable<PhantomArray> {
        return this.simulationsService.simulation$.pipe(
            take(1),
            switchMap(simulation => {
                // Create phantom array
                const name = this.generatePhantomArrayName(existingArrays, sourceArray.name + '-Copy');
                return this.phantomArraysService.create(
                    <CreatePhantomArrayRequest>{ name: name, source_array: stripResource(sourceArray) },
                    { simulation_id: simulation.id },
                );
            }),
            switchTap(phantomArray => {
                // Force update of simulation steps to fetch the steps from creating a new phantom array
                const cloneWorkload = SimulationStep.createCopyArrayStep(sourceArray, phantomArray);
                return this.simulationsService.applySteps(cloneWorkload);
            }),
        );
    }

    supportsClone(selected: ForecastedArray): supportsCloneParams {
        if (!selected) {
            return {
                supportsClone: false,
                reason: '',
            };
        }

        const supportsClone =
            selected &&
            !isPhantomArray(selected) &&
            !isCBS(selected.array.model) &&
            !(isFlashblade(selected.array.model) && !isPhantomFB(selected.array.model)) &&
            (!isFAE(selected.array.model) || this.isFAEWorkloadAndCloneSimulationsSupported) &&
            !isFA3xx(selected.array.model) &&
            !isVMware(selected.array.model);

        let reason = '';

        if (!supportsClone) {
            // We don't always want to show a message when disabled (eg if disabled due to isCreatingPhantom)
            if (isPhantomArray(selected)) {
                reason = `Not supported for simulated appliances`;
            } else if (getProduct(selected.array.product) !== Product.FA) {
                reason = `Only supported for FlashArray`;
            } else if (isVMware(selected.array.model)) {
                reason = 'Not supported for VMware';
            } else if (isFAE(selected.array.model) && !this.isFAEWorkloadAndCloneSimulationsSupported) {
                reason = 'Not supported for FlashArray//E';
            }
        }

        return {
            supportsClone: supportsClone,
            reason: reason,
        };
    }

    /**
     * Generates a unique name for a new phantom array
     */
    private generatePhantomArrayName(existingArrays: Resource[], baseName = 'New-Array'): string {
        const arrayNames = new Set<string>(existingArrays.map(a => a.name.toLowerCase()));

        let newName: string;
        for (let i = 1; i < 100; i++) {
            const suffix = i > 1 ? `-${i}` : '';
            newName = baseName + suffix;
            if (!arrayNames.has(newName.toLowerCase())) {
                break;
            }
        }

        // Either we found a unique name, or someone went crazy with the Create button and we have >100
        // Either way, good enough
        return newName;
    }
}
